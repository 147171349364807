import { makeAutoObservable, runInAction } from 'mobx';
import { Moment } from 'moment';

import { lazyInject, provide, Axios } from '../../shared/utils';
import {
  TypeOrganizationStatus,
  initialOrganizationStatus,
  EOrganizationStatus,
} from '../../../api/models/organization.model';

type TypeFilters = {
  status: TypeOrganizationStatus;
  creationDate: Moment;
  nameOrInn: string;
  serviceProvider: boolean;
  userId: string;
  employeeId?: string;
  organizationType?: string;
};

@provide.singleton()
export class FilterStore {
  @lazyInject(Axios)
  protected axios: Axios;

  constructor() {
    makeAutoObservable(this);
  }

  statusOptions = initialOrganizationStatus;

  filters: TypeFilters = {
    status: this.statusOptions.find(({ statusId }) => statusId === EOrganizationStatus.ACTIVE),
    creationDate: null,
    nameOrInn: '',
    serviceProvider: false,
    userId: '',
    employeeId: '',
    organizationType: '',
  };

  setFilterNameOrInn = (value: string) => {
    runInAction(() => {
      this.filters.nameOrInn = value;
    });
  };

  setFilterStatus = (status: TypeOrganizationStatus) => {
    runInAction(() => {
      this.filters.status = status;
    });
  };

  clearFilter = () => {
    runInAction(() => {
      this.filters.status = this.statusOptions.find(
        ({ statusId }) => statusId === EOrganizationStatus.ACTIVE
      );
      this.filters.creationDate = null;
      this.filters.nameOrInn = '';
      this.filters.serviceProvider = false;
      this.filters.userId = '';
      this.filters.employeeId = '';
      this.filters.organizationType = '';
    });
  };
}
