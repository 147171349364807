import { TabContext, TabList } from '@mui/lab';
import { Box, Button, Snackbar, Tab, TextField as MTextField } from '@mui/material';
import { Field, FormikProvider, useFormik } from 'formik';
import { Autocomplete, AutocompleteRenderInputParams, TextField } from 'formik-mui';
import { isEqual, merge } from 'lodash';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath, Prompt, useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { useModal } from '@farmlink/farmik-ui';

import {
  EChecklistAttributeType,
  IAttribute,
  IChecklistAttributeEnumItemExtended,
} from '../../../../../../../../../api/models/checklist.attribute.model';
import { ISelectOption } from '../../../../../../../../../types/selectOption';
import { CheckListsController } from '../../../../../../../../controllers/check.list.controller';
import { ChecklistsAttributeController } from '../../../../../../../../controllers/checklistAttribute.controller';
import { DictionariesController } from '../../../../../../../../controllers/dictionaries.controller';
import { useStore } from '../../../../../../../../shared/utils';
import { AdminRoutes } from '../../../../../../../routes';
import { FormInput } from '../../../../../common';
import { IChecklistShort } from '../../../../../../../../../api/models/checklist.model';
import { beforeChangeFormulaModalConfig } from '../../../../../modules/Formulas/modals';

import {
  attributeFormItemsSchemeDefaultValues,
  checklistsAttributeBuilderSchemeMap,
  EAttributeBuilderFormScheme,
  getSchemeValidation,
} from './AttributeBuilderForm.config';
import {
  ChecklistList,
  EnumDependency,
  EnumElementList,
  FormRow,
  ODZContainer,
} from './components';
import Styled from './styled';
import ALLOWED_ODZ_TYPES from './configs/ODZ.config';
import { AttributeODZController, AttributeODZStore } from './components/ODZ/mobx';
import {
  BEFORE_TOGGLE_ODZ_MODAL,
  beforeDeleteODZModal,
  beforeToggleODZModal,
  formulaODZPreviewModalConfig,
} from './components/ODZ/modals';

enum ETab {
  Params = 'Params',
  Values = 'Values',
  Checklists = 'Checklists',
  Links = 'Links',
  ODZ = 'ODZ',
}

const AttributeBuilderFrom: FC<{ attributeData?: IAttribute }> = ({ attributeData }) => {
  const { getChecklistListByParams } = useStore(CheckListsController);
  const { getAllDictionaries } = useStore(DictionariesController);
  const {
    createChecklistAttribute,
    changeChecklistAttribute,
    changeChecklistAttributeActivity,
    deleteChecklistAttribute,
    getChecklistAttributeEnumList,
    changeChecklistAttributeEnumList,
  } = useStore(ChecklistsAttributeController);
  const { getPreparedODZData, provideODZData, getSaveConsistency } = useStore(
    AttributeODZController
  );
  const { clearStore } = useStore(AttributeODZStore);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [enumList, setEnumList] = useState<IChecklistAttributeEnumItemExtended[]>([]);
  const [isDeleted, setIsDeleted] = useState(false);

  const history = useHistory();
  const { registerModalList, openModalByModalId } = useModal();

  const isViewAttribute = Boolean(attributeData);

  const [currentAttribute, setCurrentAttribute] = useState<
    keyof typeof checklistsAttributeBuilderSchemeMap | null
  >(null);
  const [currentTab, setCurrentTab] = useState<ETab>(ETab.Params);

  useEffect(() => {
    registerModalList(
      [
        beforeDeleteODZModal,
        formulaODZPreviewModalConfig,
        beforeToggleODZModal,
        beforeChangeFormulaModalConfig,
      ],
      'baseAttributeModals'
    );

    return () => {
      clearStore();
    };
  }, []);

  useEffect(() => {
    if (isViewAttribute) {
      setCurrentAttribute(attributeData.type as keyof typeof checklistsAttributeBuilderSchemeMap);
      const data: any = { ...attributeData };

      if (attributeData.type === 'ENUM') {
        getChecklistAttributeEnumList({ id: attributeData.id }).then(itemList => {
          setEnumList(itemList);
        });
      }

      setValues(data);
      provideODZData(attributeData);
    }
  }, [attributeData, isViewAttribute]);

  const updateAttribute = (payload, setSubmitting) => {
    changeChecklistAttribute(payload)
      .then(() => {
        const hasEnumValues = enumList.findIndex(item => item?.isNew) >= 0;

        setSnackbarMessage(
          `Атрибут успешно изменён!${
            hasEnumValues
              ? ' Есть несохранённые значения атрибута! Для сохранения нужно перейти во вкладку "Значения атрибута".'
              : ''
          }`
        );
        setShowSnackbar(true);
      })
      .catch(() => {
        setSnackbarMessage('Ошибка редактирования атрибута!');
        setShowSnackbar(true);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const formik = useFormik<IAttribute & { usingDictionary?: string }>({
    initialValues: isViewAttribute
      ? (attributeData as IAttribute)
      : (attributeFormItemsSchemeDefaultValues as IAttribute),
    enableReinitialize: true,
    validateOnBlur: false,
    validationSchema: yup
      .object()
      .shape(getSchemeValidation(currentAttribute as EAttributeBuilderFormScheme)),
    onSubmit: (formData, { setSubmitting }) => {
      if (isViewAttribute) {
        const payload = formData as any;

        if (currentTab === ETab.ODZ) {
          const ODZPayload = getPreparedODZData();
          merge(payload, ODZPayload);

          if (!getSaveConsistency()) {
            openModalByModalId(BEFORE_TOGGLE_ODZ_MODAL, null, () =>
              updateAttribute(payload, setSubmitting)
            );

            return;
          }
        }

        updateAttribute(payload, setSubmitting);

        return;
      }

      createChecklistAttribute(formData as any)
        .then(data => {
          setSnackbarMessage('Атрибут успешно создан!');
          setShowSnackbar(true);

          history.push(generatePath(AdminRoutes.ChecklistBasicAttribute, { attributeId: data.id }));
        })
        .catch(() => {
          setSnackbarMessage('Ошибка создания атрибута!');
          setShowSnackbar(true);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const { submitForm, setFieldValue, setValues, values, resetForm, setFieldError, errors } = formik;

  const changeAttributeActivity = useCallback(() => {
    changeChecklistAttributeActivity({
      id: values.id,
      activeValue: !values.isActive,
    })
      .then(data => {
        setSnackbarMessage('Статус успешно изменён!');
        setShowSnackbar(true);

        setFieldValue('isActive', data.isActive);
      })
      .catch(() => {
        setSnackbarMessage('Ошибка изменения статуса!');
        setShowSnackbar(true);
      });
  }, [values.isActive]);

  const attributeBuilderTypes = useMemo<ISelectOption[]>(
    () => Object.keys(checklistsAttributeBuilderSchemeMap).map(key => ({ label: key, value: key })),
    [checklistsAttributeBuilderSchemeMap]
  );

  const selectedAttributeScheme = useMemo(
    () => checklistsAttributeBuilderSchemeMap[currentAttribute],
    [currentAttribute]
  );

  const onSelectAttributeType = useCallback((e, value: ISelectOption | string) => {
    if (typeof value === 'object') {
      resetForm();
      setFieldValue('type', value.value);
      setCurrentAttribute(value.value as keyof typeof checklistsAttributeBuilderSchemeMap);
    }
  }, []);

  const handleCloseSnackbar = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowSnackbar(false);
  };

  const changeEnumList = useCallback(() => {
    changeChecklistAttributeEnumList({
      id: values.id,
      list: enumList
        .filter(item => !item?.isHide)
        .map(item => {
          if (item.isNew) {
            const cleanedItem = { ...item };

            delete cleanedItem.id;
            delete cleanedItem.isNew;
            delete cleanedItem.isHide;

            return cleanedItem;
          }

          delete item.isHide;

          return item;
        }),
    })
      .then(data => {
        setSnackbarMessage('Значения атрибутов успешно изменены!');
        setShowSnackbar(true);
        setEnumList(data);
      })
      .catch(() => {
        setSnackbarMessage('Ошибка сохранения значений атрибута!');
        setShowSnackbar(true);
      });
  }, [enumList, values]);

  const handleDeleteAttribute = useCallback(() => {
    deleteChecklistAttribute({ id: values.id })
      .then(() => {
        setIsDeleted(true);
        history.push(generatePath(AdminRoutes.ChecklistAttributes));
      })
      .catch(() => {
        setSnackbarMessage('Ошибка удаления атрибута!');
        setShowSnackbar(true);
      });
  }, [values]);

  const onChangeTab = (e, key: ETab) => {
    setCurrentTab(key);
  };

  const isUnsavedChanges = useMemo(() => !isEqual(attributeData, values), [attributeData, values]);
  const isEnumAttr = useMemo(() => attributeData?.type === EChecklistAttributeType.Enum, [
    attributeData?.type,
  ]);
  const isODZAllowed = useMemo(
    () => ALLOWED_ODZ_TYPES.includes(attributeData?.type) && attributeData?.isMultiselect === false,
    [attributeData?.type]
  );

  return (
    <TabContext value={currentTab}>
      <Prompt
        when={isViewAttribute && isUnsavedChanges && !isDeleted}
        message="Введенные данные будут потеряны"
      />
      <Styled.ButtonGroup>
        <Button
          onClick={currentTab === ETab.Values ? changeEnumList : submitForm}
          variant="contained"
        >
          Сохранить
        </Button>
        {isViewAttribute && currentTab === ETab.Params && (
          <Styled.ButtonGroup>
            <Button
              onClick={changeAttributeActivity}
              color={(values.isActive as boolean) ? 'error' : 'primary'}
              variant="contained"
            >
              {(values.isActive as boolean) ? 'Блокировать' : 'Разблокировать'}
            </Button>
            <Button onClick={handleDeleteAttribute} color="error" variant="contained">
              Удалить
            </Button>
          </Styled.ButtonGroup>
        )}
      </Styled.ButtonGroup>
      <Box>
        <TabList onChange={onChangeTab}>
          <Tab label="Параметры атрибута" value={ETab.Params} />
          {currentAttribute === 'ENUM' && isViewAttribute ? (
            <Tab label="Значения атрибута" value={ETab.Values} />
          ) : null}
          {isEnumAttr && isViewAttribute ? <Tab label="Связи" value={ETab.Links} /> : null}

          {isViewAttribute && <Tab label="Чек-листы" value={ETab.Checklists} />}

          {isODZAllowed && <Tab label="ОДЗ" value={ETab.ODZ} />}
        </TabList>
      </Box>
      <Styled.TabPanel value={ETab.Params}>
        <FormikProvider value={formik}>
          <Styled.Form>
            <FormRow>
              <Field
                name="type"
                component={Autocomplete}
                options={attributeBuilderTypes ?? []}
                onChange={onSelectAttributeType}
                style={{ minWidth: 300 }}
                disabled={isViewAttribute}
                disableClearable
                renderInput={(params: AutocompleteRenderInputParams) => (
                  <MTextField {...params} label="Тип" variant="outlined" />
                )}
              />
              {selectedAttributeScheme && selectedAttributeScheme.has('name') && (
                <>
                  <FormInput itemKey="name" />
                  <FormInput itemKey="id" disabled />
                </>
              )}
            </FormRow>
            {selectedAttributeScheme && (
              <>
                <FormRow>
                  {selectedAttributeScheme.has('isTitle') && <FormInput itemKey="isTitle" />}
                </FormRow>
                <FormRow>
                  {selectedAttributeScheme.has('isRequired') && <FormInput itemKey="isRequired" />}
                </FormRow>
                <FormRow>
                  {selectedAttributeScheme.has('isMultiselect') && (
                    <FormInput itemKey="isMultiselect" />
                  )}
                </FormRow>
                <FormRow>
                  {selectedAttributeScheme.has('calculationType') && (
                    <FormInput
                      itemKey="calculationType"
                      onChangeHandler={value => setFieldValue('calculationType', value)}
                    />
                  )}
                </FormRow>
                <FormRow $maxWidth="300px">
                  {selectedAttributeScheme.has('precision') && (
                    <FormInput
                      itemKey="precision"
                      onChangeHandler={value => setFieldValue('precision', value)}
                      stringConfig={{ regExp: /^\d$/ }}
                    />
                  )}
                </FormRow>
                <FormRow>
                  {selectedAttributeScheme.has('nestedChecklistName') && (
                    <FormInput
                      itemKey="nestedChecklistName"
                      autocompleteConfig={{
                        optionsFetchHandler: getChecklistListByParams,
                        searchingKey: 'name',
                        optionsMappingScheme: (item: IChecklistShort) => ({
                          label: item?.name,
                          value: item?.id,
                          rawData: item,
                        }),
                        onChangeMappingKey: 'checklistLink',
                        isOnMountOptionsFetch: true,
                        searchingParams: { isNested: true },
                        onSelect: value =>
                          setFieldValue('nestedChecklistName', value?.label || null),
                        isClearable: true,

                        ...(isViewAttribute
                          ? { fetchValueOnMount: { id: values?.checklistLink } }
                          : {}),
                      }}
                    />
                  )}

                  {selectedAttributeScheme.has('checklistLink') && (
                    <FormInput itemKey="checklistLink" />
                  )}
                </FormRow>
                <FormRow>
                  {selectedAttributeScheme.has('usingDictionary') && (
                    <FormInput
                      itemKey="usingDictionary"
                      autocompleteConfig={{
                        optionsFetchHandler: getAllDictionaries,
                        searchingKey: 'name',
                        onChangeMappingKey: 'dictionaryLink',
                        optionsMappingScheme: { label: 'title', value: 'remoteName' },
                        isOnMountOptionsFetch: true,
                        onSelect: value => {
                          if (value !== null) {
                            setFieldError('dictionaryLink', null);
                          }
                        },
                      }}
                    />
                  )}
                  {selectedAttributeScheme.has('dictionaryLink') && (
                    <FormInput itemKey="dictionaryLink" />
                  )}
                  {selectedAttributeScheme.has('displayParent') && (
                    <FormInput itemKey="displayParent" />
                  )}
                </FormRow>
                <FormRow>
                  {selectedAttributeScheme.has('toolTip') && (
                    <FormInput
                      itemKey="toolTip"
                      stringConfig={{ nullifyEmptyString: true, maxLength: 4000 }}
                    />
                  )}
                </FormRow>
                <FormRow>
                  {selectedAttributeScheme.has('placeholder') && (
                    <FormInput
                      itemKey="placeholder"
                      stringConfig={{ nullifyEmptyString: true, maxLength: 50 }}
                    />
                  )}
                </FormRow>
                <FormRow>
                  {selectedAttributeScheme.has('description') && (
                    <FormInput
                      itemKey="description"
                      stringConfig={{ nullifyEmptyString: true, maxLength: 100 }}
                    />
                  )}
                </FormRow>
                <FormRow>
                  <Field
                    component={TextField}
                    name={'date'}
                    type="text"
                    value={((formik.values as any)?.author as string) ?? ''}
                    label="Дата создания"
                    disabled
                    fullWidth
                  />
                  <Field
                    component={TextField}
                    name={'date'}
                    type="text"
                    value={((formik.values as any)?.date as string) ?? ''}
                    label="Автор"
                    disabled
                    fullWidth
                  />
                  <Field
                    component={TextField}
                    name={'date'}
                    type="text"
                    value={
                      ((formik.values as any)?.isActive as string) ? 'Активный' : 'Неактивный' ?? ''
                    }
                    label="Статус"
                    disabled
                    fullWidth
                  />
                </FormRow>
              </>
            )}
          </Styled.Form>
          <FormRow></FormRow>
        </FormikProvider>
      </Styled.TabPanel>
      <Styled.TabPanel value={ETab.Values}>
        <EnumElementList enumList={enumList} setEnumList={setEnumList} />
      </Styled.TabPanel>
      <Styled.TabPanel value={ETab.Checklists}>
        <ChecklistList attributeId={attributeData?.id} />
      </Styled.TabPanel>
      <Styled.TabPanel value={ETab.Links}>
        <EnumDependency />
      </Styled.TabPanel>
      <Styled.TabPanel value={ETab.ODZ}>
        <ODZContainer attribute={attributeData} />
      </Styled.TabPanel>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </TabContext>
  );
};

export default memo(AttributeBuilderFrom);
