import { Box, Switch, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { FC } from 'react';

const ODZToggler: FC<{ isChecked: boolean; setToggle: (data: boolean) => void }> = ({
  isChecked,
  setToggle,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      maxWidth={500}
      bgcolor="#ebf8ef"
      borderRadius={2}
      padding={'4px 12px'}
      margin={'8px 0'}
    >
      <Typography fontSize={16} fontWeight={700}>
        Использовать ОДЗ
      </Typography>
      <Switch defaultChecked={Boolean(isChecked)} onChange={(e, checked) => setToggle(checked)} />
    </Box>
  );
};

export default observer(ODZToggler);
