import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import { FormikProps } from 'formik';
import { FC } from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import { DatePicker, LocalizationProvider } from '@mui/lab';

import 'moment/locale/ru';

import {
  EReportConfigDecimalSeparator,
  EReportConfigDelimiter,
  ITaskReportConfig,
} from '../../../../../../../../../../api/models/reportConfig.model';

interface IProps {
  formik: FormikProps<Partial<ITaskReportConfig>>;
  handleDelete: (id) => void;
  toggleAutoUpload: (id: string, state: boolean) => void;
}

const OrganizationReportsAssistanceForm: FC<IProps> = ({
  formik,
  handleDelete,
  toggleAutoUpload,
}) => {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <TextField
            label="Сезон"
            onChange={event => {
              const targetValue = event.currentTarget.value;

              if (
                RegExp(/^-?\d+$/).test(targetValue) ||
                targetValue === '' ||
                targetValue === '-' ||
                targetValue?.length <= 4
              ) {
                formik.setFieldValue('seasonYear', targetValue);
              }
            }}
            value={formik.values.seasonYear}
            fullWidth
            error={formik.touched.reportsLocation && Boolean(formik.errors?.reportsLocation)}
            helperText={formik.errors?.reportsLocation}
          />
        </Grid>
        <Grid item xs={5}>
          <FormControl fullWidth>
            <InputLabel>Разделитель параметров</InputLabel>
            <Select
              displayEmpty
              fullWidth
              label="Разделитель параметров"
              value={formik.values?.delimiter}
              onChange={e => formik.setFieldValue('delimiter', e.target.value)}
            >
              <MenuItem value={EReportConfigDelimiter.COMMA}>Запятая (,)</MenuItem>
              <MenuItem value={EReportConfigDelimiter.TAB}>Таб (↹)</MenuItem>
              <MenuItem value={EReportConfigDelimiter.SEMICOLON}>Точка с запятой (;)</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={5}>
          <FormControl fullWidth>
            <InputLabel>Разделитель дробной части в числах</InputLabel>
            <Select
              displayEmpty
              fullWidth
              label="Разделитель дробной части в числах"
              value={formik.values?.decimalSeparator}
              onChange={e => formik.setFieldValue('decimalSeparator', e.target.value)}
            >
              <MenuItem value={EReportConfigDecimalSeparator.COMMA}>Запятая (,)</MenuItem>
              <MenuItem value={EReportConfigDecimalSeparator.DOT}>Точка (.)</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Switch
                name="withId"
                checked={Boolean(formik.values.withId)}
                onChange={(e, value) => {
                  formik.setFieldValue('withId', value);
                }}
              />
            }
            label="Выводить ID"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
            control={
              <Switch
                name="enabled"
                checked={Boolean(formik.values.enabled)}
                onChange={(e, value) => {
                  toggleAutoUpload?.(formik?.values?.id, value);

                  formik.setFieldValue('enabled', value);
                }}
              />
            }
            label="Автоматическая выгрузка"
          />
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={5}>
          <TextField
            label="Путь до папки автоматической выгрузки клиента"
            onChange={event => formik.setFieldValue('reportsLocation', event.target.value)}
            value={formik.values.reportsLocation}
            fullWidth
            error={formik.touched.reportsLocation && Boolean(formik.errors?.reportsLocation)}
            helperText={formik.errors?.reportsLocation}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            label="Путь до папки выгрузки фотоматериалов"
            onChange={event => formik.setFieldValue('filesLocation', event.target.value)}
            value={formik.values.filesLocation}
            fullWidth
            error={formik.touched.filesLocation && Boolean(formik.errors?.filesLocation)}
            helperText={formik.errors?.filesLocation}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ru">
          <Grid item xs={5}>
            <DatePicker
              value={formik?.values?.dateFrom}
              onChange={(date: Moment) => {
                if (date) formik.setFieldValue('dateFrom', date?.toDate());
              }}
              maxDate={moment(formik?.values?.dateTo)}
              renderInput={props => (
                <TextField
                  {...props}
                  label="Дата начала автоматической выгрузки отчёта"
                  error={formik.touched.dateFrom && Boolean(formik.errors?.dateFrom)}
                  helperText={formik.errors?.dateFrom}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={5}>
            <DatePicker
              value={formik?.values?.dateTo}
              onChange={(date: Moment) => {
                if (date) formik.setFieldValue('dateTo', date?.toDate());
              }}
              minDate={moment(formik?.values?.dateFrom)}
              renderInput={props => (
                <TextField
                  {...props}
                  label="Дата окончания автоматической выгрузки отчёта"
                  error={formik.touched.dateTo && Boolean(formik.errors?.dateTo)}
                  helperText={formik.errors?.dateTo}
                  fullWidth
                />
              )}
            />
          </Grid>
        </LocalizationProvider>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={2} gap={2}>
          <Button onClick={formik.submitForm} variant="contained" fullWidth>
            Сохранить
          </Button>
        </Grid>
        <Grid item xs={2} gap={2}>
          <Button
            disabled={!Boolean(formik.values?.id)}
            onClick={() => handleDelete(formik.values?.id)}
            color="error"
            variant="text"
          >
            Удалить
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OrganizationReportsAssistanceForm;
