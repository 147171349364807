import { Colors, ModalFooter, useModal } from '@farmlink/farmik-ui';
import { Box, LinearProgress, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import moment, { Moment } from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from '@mui/lab';

import Styled from './CropwiseReportModal.styles';

const CropwiseReportForm = () => {
  const [fromDate, setFromDate] = useState<Date>(null);
  const [toDate, setToDate] = useState<Date>(null);
  const [isLoading, setIsLoading] = useState(false);

  const { closeModal, getModalRuntimeHandlers } = useModal();

  const { onSuccess } = getModalRuntimeHandlers();

  const isReportDisabled = !fromDate || !toDate || isLoading;

  const generateReport = () => {
    setIsLoading(true);

    const parsedDateFrom = moment(fromDate).format('YYYY-MM-DD');
    const parsedDateTo = moment(toDate).format('YYYY-MM-DD');

    onSuccess?.({ fromDate: parsedDateFrom, toDate: parsedDateTo });
  };

  return (
    <Box>
      <Styled.Title>Выгрузка Cropwise</Styled.Title>
      <Box
        display={'flex'}
        flexDirection={'row'}
        gap={2}
        width="100%"
        height={160}
        marginBottom={4}
        justifyContent={'space-between'}
      >
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ru">
          <Box width={'100%'}>
            <DatePicker
              value={moment(fromDate)}
              onChange={(date: Moment) => setFromDate(date.toDate())}
              maxDate={moment(toDate)}
              PopperProps={{
                style: { zIndex: 10000 },
              }}
              renderInput={props => (
                <TextField {...props} label="Дата начала" error={false} fullWidth />
              )}
            />
          </Box>
          <Box width={'100%'}>
            <DatePicker
              value={moment(toDate)}
              onChange={(date: Moment) => setToDate(date.toDate())}
              minDate={moment(fromDate)}
              PopperProps={{
                style: { zIndex: 10000 },
              }}
              renderInput={props => (
                <TextField {...props} label="Дата окончания" error={false} fullWidth />
              )}
            />
          </Box>
        </LocalizationProvider>
      </Box>
      {isLoading ? (
        <>
          <Typography sx={{ fontSize: 12, color: Colors.grey }}>
            Иногда выгрузка отчёта может занять время
          </Typography>
          <LinearProgress />
        </>
      ) : (
        <Box height={22} />
      )}
      <br />

      <ModalFooter
        successButton={{
          title: 'Сформировать отчёт',
          disabled: isReportDisabled,
          handler: generateReport,
        }}
        denyButton={{ title: 'Отменить', handler: closeModal }}
      />
    </Box>
  );
};

export default CropwiseReportForm;
