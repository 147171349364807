import { GridColDef } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import { useNotificator } from '@farmlink/farmik-ui';

import { useStore } from '../../../../../../../shared/utils';
import { AgrosignalReportController } from '../../components/OrganizationReportsAgrosignal/mobx';
import { IAgrosignalHistoryFilter } from '../../components/OrganizationReportsAgrosignal/models';
import { IImportOperationModel } from '../../../../../../../../api/models/importOperation.model';
import { getNotificatorProps } from '../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../shared/constanst/notifications';
import { agrosignalGridColumns } from '../../configs';

const PAGE_SIZE_LIST = [10, 20, 50, 100];

const useAgrosignalReportHistory = ({ clientId }: { clientId?: number }) => {
  const controller = useStore(AgrosignalReportController);
  const { setNotification } = useNotificator();

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  const [totalElements, setTotalElements] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [formikValue, setFormikValue] = useState<IAgrosignalHistoryFilter>(null);
  const [valueList, setValueLst] = useState<IImportOperationModel[]>([]);

  const gridColumnConfig = useMemo<GridColDef<IImportOperationModel>[]>(
    () => agrosignalGridColumns,
    []
  );

  useEffect(() => {
    fetchHistory(formikValue);
  }, [page, size]);

  const fetchHistory = (data: IAgrosignalHistoryFilter, forcedPage?: number) => {
    setIsLoading(true);
    controller
      .getReportImportOperationList(data, forcedPage ?? page, size, clientId)
      .then(response => {
        setValueLst(response.content);
        setTotalElements(response.totalElements);
        setFormikValue(data);
      })
      .catch(error => {
        console.error(error);
        setNotification(getNotificatorProps('Ошибка получения истории', ENotificationStyles.Error));
      })
      .finally(() => setIsLoading(false));
  };

  return {
    page,
    setPage,
    size,
    setSize,
    totalElements,
    isLoading,
    valueList,
    gridColumnConfig,
    fetchHistory,
  };
};

export { useAgrosignalReportHistory, PAGE_SIZE_LIST };
