import { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { Box, Button, CircularProgress, IconButton, Tab, Tabs, Tooltip } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { observer } from 'mobx-react';
import { useModal, useNotificator } from '@farmlink/farmik-ui';
import { TabContext, TabPanel } from '@mui/lab';

import { getOrganizationMenu } from '../../../../../../../shared/menu';
import { Layout } from '../../../../../../components';
import { H1, HeaderWrapper, MainBody, MainContainer, TopSection } from '../../../../../../style';
import { AdminRoutes } from '../../../../../../routes';
import { IDeviceModel } from '../../../../../../../../api/models/device.model';
import { useStore } from '../../../../../../../shared/utils';
import DevicesService from '../../../../../../../services/devices/devices.service';
import {
  POGODAVPOLE_REPORT_MODAL_ID,
  REMOVE_DEVICE_MODAL_ID,
  pogodavpoleReportModalConfig,
  removeDeviceOwnerModalConfig,
} from '../../modals';
import { getNotificatorProps } from '../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../shared/constanst/notifications';

import {
  AddWeatherStationForm,
  AutoReportPath,
  ReportPogodaVPoleHistory,
  WeatherStationList,
} from './components';

const OrganizationReportsPogodavpole = () => {
  const devicesService = useStore(DevicesService);

  const { organizationId } = useParams<{ organizationId?: string }>();

  const [deviceList, setDeviceList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tab, setTab] = useState<'history' | 'config'>('config');

  const handleChangeTab = (event: React.SyntheticEvent, newValue: 'history' | 'config') => {
    setTab(newValue);
  };

  const history = useHistory();
  const { registerModalList, openModalByModalId } = useModal();
  const { setNotification } = useNotificator();

  const menuItems = useMemo(() => getOrganizationMenu(organizationId), [organizationId]);

  const backToList = useCallback(
    () => history.push(generatePath(AdminRoutes.OrganizationReports, { organizationId })),
    [organizationId]
  );

  const fetchDeviceList = useCallback(() => {
    setIsLoading(true);

    devicesService
      .fetchDeviceList({ ownerId: organizationId })
      .then(data => setDeviceList(data.content))
      .finally(() => {
        setIsLoading(false);
      });
  }, [organizationId]);

  useEffect(() => {
    fetchDeviceList();

    registerModalList([removeDeviceOwnerModalConfig, pogodavpoleReportModalConfig], 'reports');
  }, []);

  const addDevice = useCallback(
    (device: IDeviceModel) => {
      devicesService
        .setDeviceOwner({ id: device.id, ownerId: organizationId })
        .then(() => {
          setNotification(
            getNotificatorProps('Метеостанция успешно привязана', ENotificationStyles.Success)
          );
          fetchDeviceList();
        })
        .catch(() => {
          setNotification(
            getNotificatorProps('Ошибка привязки метеостанции', ENotificationStyles.Success)
          );
        });
    },
    [organizationId]
  );

  const removeDevice = useCallback((device: IDeviceModel) => {
    openModalByModalId(REMOVE_DEVICE_MODAL_ID, null, () => {
      devicesService
        .removeDeviceOwner({ id: device.id })
        .then(() => {
          setNotification(
            getNotificatorProps('Метеостанция успешно отвязана', ENotificationStyles.Success)
          );
          fetchDeviceList();
        })
        .catch(() => {
          setNotification(
            getNotificatorProps('Ошибка отвязки метеостанции', ENotificationStyles.Success)
          );
        });
    });
  }, []);

  return (
    <Layout menuItems={menuItems}>
      <MainContainer>
        <HeaderWrapper>
          <TopSection>
            <Box position="relative" display="flex">
              <Tooltip title="Назад к списку">
                <IconButton onClick={backToList} sx={{ maxHeight: '28px', maxWidth: '28px' }}>
                  <KeyboardArrowLeftIcon />
                </IconButton>
              </Tooltip>
              <H1>Отчеты BI. Погодавполе</H1>
            </Box>
            <Button
              variant="contained"
              onClick={() => openModalByModalId(POGODAVPOLE_REPORT_MODAL_ID, { organizationId })}
            >
              Сформировать отчёт
            </Button>
          </TopSection>
        </HeaderWrapper>

        <MainBody sx={{ height: 'calc(100vh - 204px)' }}>
          {isLoading ? (
            <CircularProgress size="30" />
          ) : (
            <TabContext value={tab}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tab} onChange={handleChangeTab}>
                  <Tab label="Настройки" value={'config'} />
                  <Tab label="История" value={'history'} />
                </Tabs>
              </Box>
              <TabPanel value="config">
                <AutoReportPath />
                <AddWeatherStationForm addDevice={addDevice} />
                <WeatherStationList deviceList={deviceList} removeDeviceHandler={removeDevice} />
              </TabPanel>
              <TabPanel value="history">
                <ReportPogodaVPoleHistory />
              </TabPanel>
            </TabContext>
          )}
        </MainBody>
      </MainContainer>
    </Layout>
  );
};

export default observer(OrganizationReportsPogodavpole);
