import { Ref } from 'react';

import { ISelectOption } from '../../../../../../types/selectOption';

import { ApiNames, TypeApiRequest, TypeApiResponse } from './../../../../../shared/utils/axios2';

interface IAdvanceGridConfig<K extends ApiNames> {
  dataFetchHandler: (payload: TypeApiRequest<K>) => Promise<TypeApiResponse<K>>;
  gridId: string;
  filter?: IAdvancedGridFilterItem<EAdvancedGridFilterItemType>[];
  onSelectRow?: (id: string | number) => void;
}

enum EAdvancedGridFilterItemType {
  String = 'string',
  Select = 'select',
  Boolean = 'boolean',
}

interface IAdvancedGridFilterItemString {
  onChangeHandler?: (value: string) => void;
  onClearHandler?: () => void | string;
  throttle?: number;
  value?: string;
}

interface IAdvancedGridFilterItemSelect {
  itemList?: ISelectOption[];
  onChangeHandler?: (value: ISelectOption) => void;
  onClearHandler?: () => void | ISelectOption;
  getItemList?: (any) => ISelectOption[];
  value?: ISelectOption;
  isToggler?: boolean;
}

interface IAdvancedGridFilterItemBoolean {
  onChangeHandler?: (value: string) => void;
  onClearHandler?: () => void | string;
  value?: string;
}

interface IAdvancedGridFilterItem<T extends EAdvancedGridFilterItemType> {
  type: T;
  label: string;
  key: string;
  ref?: Ref<HTMLInputElement>;
  config?: {
    string?: IAdvancedGridFilterItemString;
    select?: IAdvancedGridFilterItemSelect;
    boolean?: IAdvancedGridFilterItemBoolean;
  };
  filterValueTransform?: (value: any) => any;
}

export type { IAdvanceGridConfig, IAdvancedGridFilterItem };
export { EAdvancedGridFilterItemType };
