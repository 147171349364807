import { FC } from 'react';
import { observer } from 'mobx-react';
import { Box, Button, IconButton, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Colors } from '@farmlink/farmik-ui';
import { v4 } from 'uuid';

import {
  EFormulaTypes,
  IAggregationFormulaType,
  IFormulaError,
  TFormulaTypeUnion,
} from '../../../../../../../../../modules/Formulas/models';
import { FormulaBlock } from '../../../../../../../../../modules/Formulas/components/FormulaBuilder/components/FormulaBlock';
import { EODZCriteria } from '../../models';
import { COLOR_SCHEME } from '../../constants/colorScheme';

interface IProps {
  data: TFormulaTypeUnion;
  setData: (data: TFormulaTypeUnion) => void;
  setSerializedData: (data: IAggregationFormulaType) => void;
  removeData: () => void;
  comment: string;
  setComment: (data: string) => void;
  handleSetError: (error: IFormulaError, id?: string) => string;
  handleRemoveError: (id: string) => void;
  type: EODZCriteria;
}

const ODZItem: FC<IProps> = ({
  type,
  data,
  setData,
  setSerializedData,
  comment,
  setComment,
  removeData,
  handleSetError,
  handleRemoveError,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={1}
      position={'relative'}
      bgcolor={COLOR_SCHEME[type].background}
      borderLeft={`3px solid ${COLOR_SCHEME[type].border}`}
      padding={'12px'}
      borderRadius={2}
    >
      <Box position={'absolute'} right={-16} top={-16}>
        <IconButton
          onClick={() => {
            removeData();
          }}
          size="small"
          sx={{ backgroundColor: Colors.generalWhite }}
          data-test-id="formula-remove-dependency"
        >
          <DeleteIcon color="error" />
        </IconButton>
      </Box>
      <Box>
        {data ? (
          <FormulaBlock
            key={`${type}-formula`}
            implementationType={EFormulaTypes.ODZ}
            formulaItem={data}
            handleSerializedData={setSerializedData}
            handleSetError={handleSetError}
            handleRemoveError={handleRemoveError}
            data-test-id={`${type}-formula-block-parent`}
          />
        ) : (
          <Button onClick={() => setData({ clientId: v4() })}>+ Задать значение</Button>
        )}
      </Box>
      <Box>
        <TextField
          value={comment || ''}
          onChange={e => setComment(e?.target?.value || '')}
          label="Комментарий"
          multiline
          rows={2}
          size="small"
          fullWidth
          placeholder="Введите комментарий"
          sx={{ backgroundColor: '#fff', borderRadius: '9px' }}
        />
      </Box>
    </Box>
  );
};

export default observer(ODZItem);
