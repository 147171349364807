import { makeAutoObservable, runInAction } from 'mobx';
import _ from 'lodash';

import { lazyInject, provide, Axios, localizeValidationTitle } from '../../shared/utils';
import {
  TypeUserAdmin,
  initialUserAdminGender,
  initialUserAdminRole,
  FormValueError,
  EUserAdminResetPasswordStatus,
} from '../../../api/models/user.admin.model';

import { TAction, TModule } from './../../../api/models/action.model';
import { FilterStore } from './filter.store';

@provide.singleton()
export class UsersStore {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(FilterStore)
  filterStore: FilterStore;

  constructor() {
    makeAutoObservable(this);
  }

  loading = false;
  loaded = false;

  users: TypeUserAdmin[] = [];
  user: TypeUserAdmin = {} as TypeUserAdmin;
  gendersOptions = initialUserAdminGender;
  rolesOptions = initialUserAdminRole;

  private _actionList: TModule[] = [];
  private _userActionList: Set<string> = new Set();

  formValuesErrors = {};

  page = 0;
  totalPages = 0;

  operId = '';
  pendingEmail = '';
  resetPasswordStatus = EUserAdminResetPasswordStatus.SUCCESS;

  private _pageSize = 20;
  private _totalElements = 0;

  get isLoading() {
    return this.loading || !this.loaded;
  }

  get isLoaded() {
    return !this.loading && this.loaded;
  }

  get userActionListCodes() {
    return this._userActionList;
  }

  get actionList() {
    return this._actionList;
  }

  get pageSize() {
    return this._pageSize;
  }

  get totalElements() {
    return this._totalElements;
  }

  setLoading = (v: boolean) => {
    runInAction(() => {
      this.loading = v;
    });
  };

  setLoaded = (v: boolean) => {
    runInAction(() => {
      this.loaded = v;
    });
  };

  setPage = (v: number) => {
    runInAction(() => {
      this.page = v;
    });
  };

  setUsers = (users: TypeUserAdmin[], totalPages: number, totalElements: number) => {
    runInAction(() => {
      this.users = users;
      this.totalPages = totalPages;
      this._totalElements = totalElements;
    });
  };

  setUser = (user: TypeUserAdmin) => {
    runInAction(() => {
      this.user = user;
    });
  };

  setOperId = (id: string) => {
    runInAction(() => {
      this.operId = id;
    });
  };

  setPendingEmail = (email: string) => {
    runInAction(() => {
      this.pendingEmail = email;
    });
  };

  setResetPasswordStatus = (status: EUserAdminResetPasswordStatus) => {
    runInAction(() => {
      this.resetPasswordStatus = status;
    });
  };

  setFormValueErrors = (error: FormValueError) => {
    runInAction(() => {
      this.formValuesErrors = {
        ...this.formValuesErrors,
        [error.source]: localizeValidationTitle(error.title),
      };
    });
  };

  setFormValuesErrors = (errors: FormValueError[]) => {
    runInAction(() => {
      errors.forEach(({ source, title }) => {
        this.formValuesErrors = {
          ...this.formValuesErrors,
          [source]: localizeValidationTitle(title),
        };
      });
    });
  };

  setUserActionList = (actionList: TAction[]) => {
    _(actionList)
      .filter(action => action.available)
      .flatMap(action => action.code)
      .value()
      .forEach(action => {
        this._userActionList.add(action);
      });
  };

  setActionList = (actionList: TModule[]) => {
    this._actionList = actionList;
  };

  addToActionList = (actionCode: string) => {
    this._userActionList.add(actionCode);
  };

  setPageSize = (pageSize: number) => {
    this._pageSize = pageSize;
  };

  clearUserActionList = () => {
    this._userActionList.clear();
  };

  clearUsers = () => {
    this.setUsers([], 0, 0);
  };

  clearUser = () => {
    this.setUser({} as TypeUserAdmin);
  };

  clearFormValuesErrors = () => {
    runInAction(() => {
      this.formValuesErrors = {};
    });
  };
}
