import { Autocomplete, TextField } from '@mui/material';
import { debounce } from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';

import { ISelectOption, ISelectOptionExtended } from '../../../../types/selectOption';
import { ApiNames, TypeApiRequest, TypeApiResponse } from '../../utils';

interface IAutocompleteWithSearchProps {
  selectedValue: ISelectOptionExtended | string;
  setSelectedOption: (value: ISelectOption | string) => void;
  fetchHandler: <K extends ApiNames>(payload?: TypeApiRequest<K>) => Promise<TypeApiResponse<K>>;
  responseMappingHandler: (data: TypeApiResponse<ApiNames>) => ISelectOptionExtended[];
  searchPropertyKey?: string;
  defaultRequestPayload?: Record<string, any>;
  additionalRequestPayload?: Record<string, any>;
  label?: string;
  placeholder?: string;
  fullWidth?: boolean;
  isClearable?: boolean;
}

const AutocompleteWithSearch: FC<IAutocompleteWithSearchProps> = ({
  selectedValue,
  setSelectedOption,
  fetchHandler,
  defaultRequestPayload,
  responseMappingHandler,
  searchPropertyKey,
  label,
  placeholder,
  fullWidth,
  additionalRequestPayload,
  isClearable,
}) => {
  const [optionList, setOptionList] = useState<ISelectOptionExtended[]>([]);
  const [inputValue, setInputValue] = useState('');

  const onInputChange = useCallback((e, value) => {
    setInputValue(value);
  }, []);

  const handleFetch = searchValue => {
    const payload = { ...defaultRequestPayload, ...(additionalRequestPayload || {}) };

    if (searchPropertyKey) {
      payload[searchPropertyKey] = searchValue;
    }

    fetchHandler(payload).then(data => setOptionList(responseMappingHandler(data)));
  };

  const handleFetchOrganization = useCallback(debounce(handleFetch, 500), [
    additionalRequestPayload,
  ]);

  const handleOnChange = useCallback((e, value: ISelectOption | string) => {
    if (typeof value === 'object') {
      setSelectedOption(value);
    }
  }, []);

  useEffect(() => {
    handleFetchOrganization(inputValue);
  }, [inputValue, additionalRequestPayload]);

  return (
    <Autocomplete
      options={optionList}
      fullWidth={fullWidth}
      renderInput={props => (
        <TextField
          {...props}
          label={label || 'Поиск'}
          placeholder={placeholder || 'Введите значение для поиска'}
        />
      )}
      disableClearable={!isClearable}
      disablePortal
      inputValue={inputValue}
      value={selectedValue}
      onChange={handleOnChange}
      onInputChange={onInputChange}
    />
  );
};

export default AutocompleteWithSearch;
