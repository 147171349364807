import moment from 'moment';
import _ from 'lodash';

import { TypeApiRequest, lazyInject, provide } from '../../../../../../../../../../shared/utils';
import { ITaskReportConfig } from '../../../../../../../../../../../api/models/reportConfig.model';
import { AssistanceReportService } from '../../../../../../../../../../services/reports/assistance/assistanceReports.service';

@provide.transient()
class AssistanceTaskReportController {
  @lazyInject(AssistanceReportService)
  reportService: AssistanceReportService;

  getReportsConfig = async (orgId: string): Promise<ITaskReportConfig | false> => {
    const response = await this.reportService.getAssistanceTaskReportConfig({
      organizationId: orgId,
    });

    if (!response) {
      return false;
    }

    return response.content[0];
  };

  createReportConfig = async (organizationId: string, config: ITaskReportConfig) => {
    const response = await this.reportService.createAssistanceTaskReportConfig(
      this.prepareFormToCreateConfig(organizationId, config)
    );

    return response;
  };

  updateReportConfig = async (
    configId: string,
    config: Partial<ITaskReportConfig>,
    defaultConfig: Partial<ITaskReportConfig>
  ) => {
    const changes: Partial<ITaskReportConfig> = _.reduce(
      config,
      (result, value, key) => {
        return _.isEqual(value, defaultConfig[key]) ? result : _.extend(result, { [key]: value });
      },
      {}
    );

    const payload = {
      id: configId,
      ...changes,
      ...(changes?.dateFrom && { dateFrom: this.parseDateToString(changes.dateFrom) }),
      ...(changes?.dateTo && { dateTo: this.parseDateToString(changes.dateTo) }),
    };

    const response = await this.reportService.updateAssistanceTaskReportConfigBy(payload);

    return response;
  };

  toggleAutoUpload = async (configId: string, state: boolean) => {
    const response = await this.reportService.toggleAssistanceTaskReportAutoUpload(configId, state);

    return response;
  };

  deleteReportConfig = async (configId: string) => {
    const response = await this.reportService.deleteAssistanceTaskReportConfigBy({ id: configId });

    return response;
  };

  private prepareFormToCreateConfig = (
    organizationId: string,
    config: ITaskReportConfig
  ): TypeApiRequest<'createAssistanceTaskReportsConfig'> => {
    const output: TypeApiRequest<'createAssistanceTaskReportsConfig'> = {
      organizationId,
      ...config,
      ...(config?.dateFrom && { fromDate: this.parseDateToString(config.dateFrom) }),
      ...(config?.dateTo && { toDate: this.parseDateToString(config.dateTo) }),
    };

    return output;
  };

  private parseDateToString = (date: string): string => moment(date).format('YYYY-MM-DD');
}

export default AssistanceTaskReportController;
