import { TypeApiRoute } from '../../../models/type.api.request';

import {
  IAttribute,
  EChecklistAttributeType,
  EChecklistAttributeCalculationType,
} from './../../../models/checklist.attribute.model';

type TypeRequest = {
  name: string;
  isRequired: boolean;
  isActive: boolean;
  checklistLink: string;
  checklistLinkPublicId: string;
  dictionaryLink: string;
  isMultiselect: boolean;
  displayParent: boolean;
  toolTip: string;
  placeholder: string;
  description: string;
  calculationType: EChecklistAttributeCalculationType;
  isTitle: boolean;
  precision?: number;
  type: EChecklistAttributeType;
};

type TypeResponse = IAttribute;

type TypeError = {};

export const createChecklistAttribute: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: request => `/api/as-fields/admin/attribute/`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
