import * as yup from 'yup';

const TASK_REPORT_SCHEME = yup.object().shape({
  seasonYear: yup
    .number()
    .typeError('Поле обязательно для заполнения')
    .required('Поле обязательно для заполнения'),
  decimalSeparator: yup
    .string()
    .typeError('Поле обязательно для заполнения')
    .required('Поле обязательно для заполнения'),
  withId: yup
    .boolean()
    .typeError('Поле обязательно для заполнения')
    .required('Поле обязательно для заполнения'),
  enabled: yup
    .boolean()
    .typeError('Поле обязательно для заполнения')
    .required('Поле обязательно для заполнения'),
  reportsLocation: yup
    .string()
    .typeError('Поле обязательно для заполнения')
    .required('Поле обязательно для заполнения'),
  filesLocation: yup
    .string()
    .typeError('Поле обязательно для заполнения')
    .required('Поле обязательно для заполнения'),
  dateFrom: yup
    .string()
    .typeError('Поле обязательно для заполнения')
    .required('Поле обязательно для заполнения'),
  dateTo: yup.string().nullable(),
});

export default TASK_REPORT_SCHEME;
