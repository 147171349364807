import { Axios, TypeApiRequest, lazyInject, provide } from '../../../shared/utils';

@provide.transient()
export class AssistanceReportService {
  @lazyInject(Axios)
  protected axios: Axios;

  getAssistanceTaskReportConfig = async (
    payload: TypeApiRequest<'getAssistanceTaskReportsConfig'>
  ) => {
    try {
      const response = await this.axios.api.getAssistanceTaskReportsConfig(payload, {
        omit: ['page', 'size'],
        query: { page: payload.page, size: payload.size },
      });

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  getAssistanceTaskReportConfigById = async (
    payload: TypeApiRequest<'getAssistanceTaskReportsConfigById'>
  ) => {
    try {
      const response = await this.axios.api.getAssistanceTaskReportsConfigById(payload, {
        omit: ['id'],
      });

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  createAssistanceTaskReportConfig = async (
    payload: TypeApiRequest<'createAssistanceTaskReportsConfig'>
  ) => {
    try {
      const response = await this.axios.api.createAssistanceTaskReportsConfig(payload);

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  updateAssistanceTaskReportConfigBy = async (
    payload: TypeApiRequest<'updateAssistanceTaskReportsConfig'>
  ) => {
    try {
      const response = await this.axios.api.updateAssistanceTaskReportsConfig(payload, {
        omit: ['id'],
      });

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  deleteAssistanceTaskReportConfigBy = async (
    payload: TypeApiRequest<'deleteAssistanceTaskReportsAutoUpload'>
  ) => {
    try {
      const response = await this.axios.api.deleteAssistanceTaskReportsAutoUpload(payload, {
        omit: ['id'],
      });

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  toggleAssistanceTaskReportAutoUpload = async (id: string, state: boolean) => {
    try {
      if (state) {
        const response = await this.axios.api.enableAssistanceTaskReportsAutoUpload(
          { id },
          {
            omit: ['id'],
          }
        );

        return response;
      } else {
        const response = await this.axios.api.disableAssistanceTaskReportsAutoUpload(
          { id },
          {
            omit: ['id'],
          }
        );

        return response;
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };

  getAssistanceFieldReportConfig = async (
    payload: TypeApiRequest<'getAssistanceFieldReportsConfig'>
  ) => {
    try {
      const response = await this.axios.api.getAssistanceFieldReportsConfig(payload, {
        omit: ['page', 'size'],
        query: { page: payload.page, size: payload.size },
      });

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  getAssistanceFieldReportConfigById = async (
    payload: TypeApiRequest<'getAssistanceFieldReportsConfigById'>
  ) => {
    try {
      const response = await this.axios.api.getAssistanceFieldReportsConfigById(payload, {
        omit: ['id'],
      });

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  createAssistanceFieldReportConfig = async (
    payload: TypeApiRequest<'createAssistanceFieldReportsConfig'>
  ) => {
    try {
      const response = await this.axios.api.createAssistanceFieldReportsConfig(payload);

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  updateAssistanceFieldReportConfigBy = async (
    payload: TypeApiRequest<'updateAssistanceFieldReportsConfig'>
  ) => {
    try {
      const response = await this.axios.api.updateAssistanceFieldReportsConfig(payload, {
        omit: ['id'],
      });

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  deleteAssistanceFieldReportConfigBy = async (
    payload: TypeApiRequest<'deleteAssistanceFieldReportsAutoUpload'>
  ) => {
    try {
      const response = await this.axios.api.deleteAssistanceFieldReportsAutoUpload(payload, {
        omit: ['id'],
      });

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  toggleAssistanceFieldReportAutoUpload = async (id: string, state: boolean) => {
    try {
      if (state) {
        const response = await this.axios.api.enableAssistanceFieldReportsAutoUpload(
          { id },
          {
            omit: ['id'],
          }
        );

        return response;
      } else {
        const response = await this.axios.api.disableAssistanceFieldReportsAutoUpload(
          { id },
          {
            omit: ['id'],
          }
        );

        return response;
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };
}
