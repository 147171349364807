import { Box, IconButton, Tooltip } from '@mui/material';
import { FC, useCallback, useState, MouseEvent, memo } from 'react';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import { SvgIconComponent } from '@mui/icons-material';

interface IAdditionalAdornmentButton {
  icon: SvgIconComponent;
  onClick?: () => void;
  tooltip?: string;
  isDisabled?: boolean;
  isHided?: boolean;
}

interface IProps {
  onEditClick?: () => void;
  onDeleteClick?: () => void;
  onLockClick?: () => void;
  onUnlockClick?: () => void;
  hideEdit?: boolean;
  hideDelete?: boolean;
  hideLockToggler?: boolean;
  isLockOpen?: boolean;
  disableEdit?: boolean;
  disableDelete?: boolean;
  disableLockToggler?:
    | {
        isLockOpen?: boolean;
        isLockClose?: boolean;
      }
    | boolean;
  tooltipConfig?: {
    lockToggler?: {
      isLockOpen?: string;
      isLockClose?: string;
    };
  };
  isReverseDeleteButton?: boolean;
  additionalButtonList?: IAdditionalAdornmentButton[];
}
const AdornmentButtons: FC<IProps> = ({
  hideDelete,
  hideEdit,
  onDeleteClick,
  onEditClick,
  hideLockToggler,
  isLockOpen,
  onLockClick,
  onUnlockClick,
  disableDelete,
  disableEdit,
  disableLockToggler,
  tooltipConfig,
  isReverseDeleteButton,
  additionalButtonList,
}) => {
  const [isDeletingStage, setIsDeletingStage] = useState(false);

  const handleChangeStage = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      onEditClick?.();
    },
    [onEditClick]
  );

  const handleDeleteStage = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      onDeleteClick?.();

      setIsDeletingStage(false);
    },
    [onDeleteClick]
  );

  const handleDeleteConfirmation = useCallback((e: MouseEvent, state: boolean) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDeletingStage(state);
  }, []);

  const handleLockProxy = useCallback((e: MouseEvent, func?: (e?: MouseEvent) => void) => {
    e.preventDefault();
    e.stopPropagation();

    func?.(e);
  }, []);

  return isDeletingStage ? (
    <Box gap={0} display="flex" flexDirection={isReverseDeleteButton ? 'row-reverse' : 'row'}>
      <IconButton onClick={handleDeleteStage}>
        <DoneIcon />
      </IconButton>
      <IconButton
        onClick={e => {
          handleDeleteConfirmation(e, false);
        }}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  ) : (
    <Box gap={0} display="flex" flexDirection="row">
      {additionalButtonList?.map(({ icon: Icon, isDisabled, isHided, onClick, tooltip }, i) => {
        return (
          !isHided && (
            <Tooltip key={i} title={tooltip ?? ''}>
              <IconButton onClick={onClick} disabled={isDisabled}>
                <Icon />
              </IconButton>
            </Tooltip>
          )
        );
      })}
      {!hideEdit && (
        <IconButton onClick={handleChangeStage} disabled={disableEdit}>
          <CreateIcon />
        </IconButton>
      )}
      {!hideDelete && (
        <IconButton
          onClick={e => {
            handleDeleteConfirmation(e, true);
          }}
          disabled={disableDelete}
        >
          <DeleteIcon />
        </IconButton>
      )}
      {hideLockToggler ? null : isLockOpen ? (
        <Tooltip title={tooltipConfig?.lockToggler?.isLockOpen ?? 'Заблокировать'}>
          {(
            typeof disableLockToggler === 'object'
              ? disableLockToggler?.isLockOpen
              : disableLockToggler
          ) ? (
            <IconButton disableRipple>
              <LockOpenOutlinedIcon color="primary" sx={{ opacity: '0.5' }} />
            </IconButton>
          ) : (
            <IconButton
              color="primary"
              onClick={e => handleLockProxy(e, onLockClick)}
              sx={{ pointerEvents: 'all  !important' }}
            >
              <LockOpenOutlinedIcon />
            </IconButton>
          )}
        </Tooltip>
      ) : (
        <Tooltip title={tooltipConfig?.lockToggler?.isLockClose ?? 'Разблокировать'}>
          {(
            typeof disableLockToggler === 'object'
              ? disableLockToggler?.isLockClose
              : disableLockToggler
          ) ? (
            <IconButton disableRipple>
              <LockOutlinedIcon color="error" sx={{ opacity: '0.5' }} />
            </IconButton>
          ) : (
            <IconButton
              color="error"
              onClick={e => handleLockProxy(e, onUnlockClick)}
              sx={{ pointerEvents: 'all !important' }}
            >
              <LockOutlinedIcon />
            </IconButton>
          )}
        </Tooltip>
      )}
    </Box>
  );
};

export default memo(AdornmentButtons);
