import { Button, IconButton, TextField, Tooltip } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Dispatch, FC, SetStateAction, useCallback, useMemo, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import Lock from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Box } from '@mui/system';
import { uniqueId } from 'lodash';

import { IChecklistAttributeEnumItemExtended } from '../../../../../../../../../../../api/models/checklist.attribute.model';
import { AutocompleteWithSearch } from '../../../../../../../../../../shared/components';
import { useStore } from '../../../../../../../../../../shared/utils';
import { OrganizationsController } from '../../../../../../../../../../controllers/organizations.controller';
import { TypeOrganization } from '../../../../../../../../../../../api/models/organization.model';

import Styled from './styled';

interface IProps {
  enumList: IChecklistAttributeEnumItemExtended[];
  setEnumList: (item: IChecklistAttributeEnumItemExtended[], isReplace?: boolean) => void;
  additionalItemData?: Record<string, string | number | boolean>;
  includeOrganization?: boolean;
}

const EnumElementList: FC<IProps> = ({
  enumList,
  setEnumList,
  additionalItemData,
  includeOrganization,
}) => {
  const { fetchOrganizationListByParams } = useStore(OrganizationsController);

  const [newValue, setNewValue] = useState('');
  const [selectedOrganization, setSelectedOrganization] = useState(null);

  const handleChangeItemActivity = useCallback(
    (item: IChecklistAttributeEnumItemExtended) => {
      enumList.find(enumItem => enumItem.id === item.id).isActive = !item.isActive;
      setEnumList(enumList);
    },
    [enumList]
  );

  const handleDeleteItem = useCallback(
    (item: IChecklistAttributeEnumItemExtended) => {
      if (item?.isNew) {
        return setEnumList(
          enumList.filter(enumItem => item.id !== enumItem.id),
          true
        );
      } else {
        const enumValue = enumList.find(enumItem => enumItem.id === item.id);

        if (enumValue) {
          enumValue.isHide = !item?.isHide;
        }
      }

      setEnumList(enumList);
    },
    [enumList]
  );

  const addNewItem = useCallback(() => {
    const newItem: IChecklistAttributeEnumItemExtended = {
      id: uniqueId('newValue'),
      isActive: true,
      isNew: true,
      value: newValue,

      ...(additionalItemData ? additionalItemData : {}),
    };

    if (includeOrganization) {
      newItem.organizationId = selectedOrganization.value;
    }

    setEnumList([...(enumList || []), newItem]);
    setNewValue('');
    setSelectedOrganization(null);
  }, [newValue, enumList, selectedOrganization, includeOrganization]);

  const handleEditItem = useCallback(
    (id: string, value: string) => {
      const changedEnumList = [...enumList];

      const editedElement = changedEnumList.find(item => item.id === id);

      if (editedElement) {
        editedElement.value = value;
      }

      setEnumList(changedEnumList);
    },
    [enumList]
  );

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'value',
        headerName: 'Значение',
        flex: 1,
        editable: true,
      },
      ...(includeOrganization
        ? [{ field: 'organizationId', headerName: 'Организация', flex: 1 }]
        : []),
      { field: 'id', headerName: 'ID значения', flex: 1 },
      {
        field: 'isActive',
        headerName: 'Действия',
        sortable: false,
        minWidth: 100,
        renderCell: params => (
          <Styled.ItemAction>
            <Tooltip title={params.value ? 'Заблокировать' : 'Разблокировать'}>
              <IconButton onClick={() => handleChangeItemActivity(params.row)}>
                {params.value ? <LockOpenIcon color="primary" /> : <Lock color="error" />}
              </IconButton>
            </Tooltip>
            <Tooltip
              title={
                params.row?.canDelete === false
                  ? 'Запись используется в чек-листах'
                  : params.row.isHide
                  ? 'Вернуть'
                  : 'Удалить'
              }
            >
              <IconButton
                onClick={() => handleDeleteItem(params.row)}
                disableRipple={params.row?.canDelete === false}
                disabled={params.row?.canDelete === false}
              >
                {params.row.isHide ? <RestoreFromTrashIcon /> : <DeleteIcon />}
              </IconButton>
            </Tooltip>
          </Styled.ItemAction>
        ),
      },
    ],
    [enumList, includeOrganization]
  );

  const isNewItemValid = useMemo(() => {
    return newValue.length > 0 && includeOrganization ? Boolean(selectedOrganization) : true;
  }, [newValue, selectedOrganization]);

  return (
    <div>
      <Box display="flex" alignItems="center" gap="12px">
        <TextField
          label="Значение"
          sx={{ maxWidth: 320, width: '100%' }}
          value={newValue}
          onChange={e => setNewValue(e.target.value)}
        />
        {includeOrganization && (
          <AutocompleteWithSearch
            selectedValue={selectedOrganization}
            setSelectedOption={setSelectedOrganization}
            fetchHandler={fetchOrganizationListByParams}
            additionalRequestPayload={{ page: 0, size: 50 }}
            searchPropertyKey={'nameOrInn'}
            responseMappingHandler={(data: TypeOrganization[]) =>
              data.map(item => ({
                label: `${item?.name ? `${item.name} ${item?.INN ? ' / ' : ''}` : ''}${
                  item?.INN || ''
                }`,
                name: item.name,
                value: item.organizationId,
                inn: item.INN,
                id: item.organizationId,
              }))
            }
            label="Наименование или ИНН"
            fullWidth
          />
        )}

        <Button
          disabled={!isNewItemValid}
          onClick={addNewItem}
          sx={{ margin: '12px 0' }}
          variant="contained"
        >
          Добавить
        </Button>
      </Box>

      <div style={{ display: 'flex', height: '95%', minHeight: '500px' }}>
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            rows={enumList}
            columns={columns}
            onCellEditCommit={params => handleEditItem(params.id as string, params.value)}
            hideFooter
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            getRowClassName={params => {
              let classes = '';

              if (params.row?.isHide) {
                return (classes += ' row-to-delete');
              }

              if (params.row?.isNew) {
                return (classes += ' new-row');
              }

              return 'classes';
            }}
            localeText={{ noRowsLabel: 'Нет результатов' }}
            sx={{
              '.row-to-delete': {
                opacity: 0.5,
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default EnumElementList;
