import { Autocomplete, Box, Button, CircularProgress, TextField } from '@mui/material';
import { debounce } from 'lodash';
import { FC, memo, useCallback, useEffect, useState } from 'react';

import { useStore } from '../../../../../../../../../shared/utils';
import DevicesService from '../../../../../../../../../services/devices/devices.service';
import { ISelectOptionExtended } from '../../../../../../../../../../types/selectOption';
import { IDeviceModel } from '../../../../../../../../../../api/models/device.model';

interface IProps {
  addDevice?: (device: IDeviceModel) => void;
  onSelectDevice?: (device: IDeviceModel) => void;
  isAllOwners?: boolean;
  isHideAddButton?: boolean;
  organizationId?: string;
}

const AddWeatherStationForm: FC<IProps> = ({
  addDevice,
  onSelectDevice,
  isAllOwners,
  isHideAddButton,
  organizationId,
}) => {
  const deviceService = useStore(DevicesService);

  const [optionList, setOptionList] = useState<ISelectOptionExtended<IDeviceModel>[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [listboxNode, setListboxNode] = useState<HTMLDivElement>(null);
  const [position, setPosition] = useState(0);

  const [inputValue, setInputValue] = useState('');
  const [deviceValue, setDeviceValue] = useState<ISelectOptionExtended<IDeviceModel>>(null);

  const fetchDeviceList = (context?: string) => {
    setIsLoading(true);

    deviceService
      .fetchDeviceList({
        withoutOwnerOnly: !isAllOwners,
        context,
        page,
        size: 100,
        ...(organizationId && { ownerId: organizationId }),
      })
      .then(data => {
        const parsedDeviceList = data.content.map<ISelectOptionExtended<IDeviceModel>>(device => ({
          label: `${device.name}${device?.label ? ` [${device.label}]` : ''}`,
          value: device.id,
          rawData: device,
        }));

        setOptionList(parsedDeviceList);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    debouncedFetch(inputValue);
  }, [inputValue]);

  useEffect(() => {
    if (listboxNode) {
      listboxNode.scrollTop = position;
    } else {
      setPage(0);
    }
  }, [position, listboxNode]);

  const debouncedFetch = useCallback(debounce(fetchDeviceList, 300), []);

  const handleScroll = event => {
    if (event.currentTarget) {
      setListboxNode(event.currentTarget);
    }

    if (!listboxNode) {
      return;
    }

    const y = listboxNode?.scrollTop + listboxNode?.clientHeight;

    if (listboxNode.scrollHeight - y <= 1) {
      setPosition(y);
      setPage(page + 1);
      fetchDeviceList();
    }
  };

  const handleDeviceAdding = useCallback(() => {
    addDevice?.(deviceValue.rawData);

    setInputValue('');
    setDeviceValue(null);
  }, [deviceValue]);

  return (
    <Box display="flex" flexDirection="row" gap={2} marginTop={2}>
      <Autocomplete
        options={optionList}
        value={deviceValue}
        disablePortal
        renderInput={params => (
          <TextField
            {...params}
            label="Выберите метеостанцию"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        ListboxProps={{
          onScroll: handleScroll,
          role: 'list-box',
        }}
        onInputChange={(e, value) => setInputValue(value)}
        onChange={(e, value) => {
          if (typeof value === 'object') {
            onSelectDevice?.(value?.rawData);
            setDeviceValue(value);
          }
        }}
        inputValue={inputValue}
        fullWidth
      />
      {!isHideAddButton && (
        <Button variant="contained" disabled={!deviceValue?.rawData} onClick={handleDeviceAdding}>
          Добавить
        </Button>
      )}
    </Box>
  );
};

export default memo(AddWeatherStationForm);
