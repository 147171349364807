import { makeAutoObservable, runInAction, toJS } from 'mobx';
import _, { isNil, orderBy } from 'lodash';
import { ENotificationType, ENotificatorTheme, NotificatorProps } from '@farmlink/farmik-ui';

import { lazyInject, provide, TypeApiRequest } from '../shared/utils';
import {
  EChecklistAttributeType,
  IAttributeInChecklist,
  IFileLinkInstanceClientOnly,
} from '../../api/models/checklist.attribute.model';

import { IChecklistAttribute } from './../../api/models/checklist.model';
import { ChecklistAttributesStore } from './../stores/checkLists/attributes/checklistAttributes.store';
import { ChecklistAttributeService } from './../services/checklist/attributes/checklistAttribute.service';

@provide.singleton()
export class ChecklistsAttributeController {
  @lazyInject(ChecklistAttributesStore)
  protected checklistAttributesStore: ChecklistAttributesStore;

  @lazyInject(ChecklistAttributeService)
  protected checklistAttributeService: ChecklistAttributeService;

  constructor() {
    makeAutoObservable(this);
  }

  get checklistAttributeListByStageId() {
    return this.checklistAttributesStore.checklistAttributeListByStageId;
  }

  get checklistAttributeListByAllStages() {
    return toJS(
      Array.from(this.checklistAttributesStore.checklistAttributeListByStageId.values()).flatMap(
        item => item
      )
    );
  }

  get checklistAttributeListWithoutStage() {
    return this.checklistAttributesStore.checklistAttributeListWithoutStage;
  }

  get currentChecklistAttributeEdit() {
    return this.checklistAttributesStore.currentChecklistAttributeEdit;
  }

  get currentChecklistAttributeEditFileList() {
    return this.checklistAttributesStore.currentChecklistAttributeEditFileList;
  }

  get currentChecklistAttributeActiveFile() {
    return this.checklistAttributesStore.currentChecklistAttributeActiveFileId;
  }

  get currentChecklistAttributeUserDictionaryLinkValueList() {
    return this.checklistAttributesStore.currentChecklistAttributeUserDictionaryLinkValueList;
  }

  getChecklistAttributeListByStageId = (id: string) => {
    return runInAction(() =>
      toJS(this.checklistAttributesStore.checklistAttributeListByStageId).get(id)
    );
  };

  getChecklistAttributesByParams = (
    payload: TypeApiRequest<'getChecklistAttributeListByParams'>
  ) => {
    return this.checklistAttributeService.fetchChecklistAttributeListByParams(payload);
  };

  createChecklistAttribute = (payload: TypeApiRequest<'createChecklistAttribute'>) => {
    return this.checklistAttributeService.createChecklistAttribute(payload);
  };

  getChecklistAttribute = (payload: TypeApiRequest<'getChecklistAttribute'>) => {
    return this.checklistAttributeService.getChecklistAttribute(payload);
  };

  getChecklistAttributeList = (payload: TypeApiRequest<'getChecklistAttributeList'>) => {
    return this.checklistAttributeService.getChecklistAttributeList(payload);
  };

  changeChecklistAttribute = (payload: TypeApiRequest<'changeChecklistAttribute'>) => {
    return this.checklistAttributeService.changeChecklistAttribute(payload);
  };

  changeChecklistAttributeActivity = (
    payload: TypeApiRequest<'changeChecklistAttributeActivity'>
  ) => {
    return this.checklistAttributeService.changeChecklistAttributeActivity(payload);
  };

  deleteChecklistAttribute = (payload: TypeApiRequest<'deleteChecklistAttribute'>) => {
    return this.checklistAttributeService.deleteChecklistAttribute(payload);
  };

  getChecklistAttributeEnumList = (payload: TypeApiRequest<'getChecklistAttributeEnumList'>) => {
    return this.checklistAttributeService.getChecklistAttributeEnumList(payload);
  };

  changeChecklistAttributeEnumList = (
    payload: TypeApiRequest<'changeChecklistAttributeEnumList'>
  ) => {
    return this.checklistAttributeService.changeChecklistAttributeEnumList(payload);
  };

  getActiveChecklistAttributeList = (
    payload: TypeApiRequest<'getActiveChecklistAttributeList'>
  ) => {
    return this.checklistAttributeService.getActiveChecklistAttributeList(payload);
  };

  validateAttributeInChecklist = (attribute: IAttributeInChecklist): Error | null => {
    if (attribute?.fileRequired) {
      // const fileInstance = this.currentChecklistAttributeEditFileList.find(
      //   item => item.clientOnlyId === this.currentChecklistAttributeActiveFile
      // );
      // // if (!fileInstance) {
      // //   // @ts-ignore
      // //   return Error('Требуется активный атрибут для фотографии', { cause: 'fileRequired' });
      // // }
      // const existOrderList = Array.from(this.checklistAttributeListByStageId.values())
      //   .flatMap(item => item)
      //   .flatMap(item => item?.order || 0);
      // if (existOrderList.includes(attribute.order + 1)) {
      //   // @ts-ignore
      //   return Error(`Порядковый номер для аттрибута занят: ${attribute.order + 1}`, {
      //     cause: 'fileRequired',
      //   });
      // }
    }

    return null;
  };

  createAttributeInChecklist = (
    payload: TypeApiRequest<'createAttributeInChecklist'>,
    defaultAttributeType?: EChecklistAttributeType
  ) => {
    const validation = this.validateAttributeInChecklist(payload);

    if (validation) {
      return Promise.reject(validation);
    }

    return this.checklistAttributeService.createAttributeInChecklist(payload).then(resp => {
      const changedFileLinks = this.currentChecklistAttributeEditFileList.filter(
        item => item.isDirty
      );

      if (changedFileLinks?.length) {
        Promise.all(this.updateNestedFileAttribute(resp, changedFileLinks));
      }

      return resp;
    });
  };

  changeAttributeInChecklist = (
    payload: TypeApiRequest<'changeAttributeInChecklist'> & { order: number },
    defaultAttributeType?: EChecklistAttributeType,
    openNotification?: (notification: NotificatorProps) => void
  ) => {
    const requestData = { ...payload };

    const validation = this.validateAttributeInChecklist(requestData);
    // const isFileRequired = isNil(requestData?.fileRequired)
    //   ? this.currentChecklistAttributeEdit
    //   : requestData?.fileRequired;
    const isSameOrder = requestData?.order === this.currentChecklistAttributeEdit?.order;

    if (validation) {
      return Promise.reject(validation);
    }

    if (isSameOrder) {
      delete requestData?.order;
    }

    return this.checklistAttributeService.changeAttributeInChecklist(requestData)?.then(resp => {
      const changedFileLinks = this.currentChecklistAttributeEditFileList.filter(
        item => item.isDirty
      );

      if (changedFileLinks?.length) {
        Promise.all(this.updateNestedFileAttribute(resp, changedFileLinks));
      }

      if (
        defaultAttributeType === EChecklistAttributeType.UserDictionaryLink &&
        this.currentChecklistAttributeUserDictionaryLinkValueList.length
      ) {
        this.saveUserDictionaryValues(resp.attribute.id).catch(() => {
          openNotification({
            message: 'Ошибка сохранения USER_DICTIONARY',
            style: {
              placement: 'bottom-left',
              type: ENotificationType.Warning,
              theme: ENotificatorTheme.Dark,
            },
          });
        });
      }

      return resp;
    });
  };

  saveUserDictionaryValues = async (attributeId: string) => {
    const parsedValues = _(
      toJS(this.checklistAttributesStore.currentChecklistAttributeUserDictionaryLinkValueList)
    )
      .filter(item => !item?.isHide)
      .map(item => {
        if (item.isNew) {
          const cleanedItem = { ...item };

          delete cleanedItem.id;
          delete cleanedItem.isNew;
          delete cleanedItem.isHide;
          delete cleanedItem.canDelete;

          return cleanedItem;
        }

        delete item.isHide;
        delete item.canDelete;

        return item;
      })
      .groupBy('organizationId')
      .value();

    const promiseList = [];

    Object.entries(parsedValues).forEach(([organizationId, valueList]) => {
      if (organizationId && valueList?.length > 0 && organizationId !== 'undefined') {
        promiseList.push(
          this.changeChecklistAttributeUserDictionaryList({
            attributeId,
            organizationId,
            list: Array.from(Object.values(valueList)),
          })
        );
      }
    });

    const changedUserDictionaries = await Promise.all(promiseList);

    this.checklistAttributesStore.clearCurrentChecklistAttributeUserDictionaryLinkValueList();

    return changedUserDictionaries;
  };

  setChecklistAttributeListByStageId = (map: typeof this.checklistAttributeListByStageId) => {
    this.checklistAttributesStore.setChecklistAttributeListByStageId(map);
  };

  addAttributeListToStage = (stageId: string, attributeList: IChecklistAttribute[]) => {
    runInAction(() => {
      const parsedAttributeList = _(
        this.checklistAttributesStore.checklistAttributeListByStageId.get(stageId) || []
      )
        .filter(item => !attributeList.flatMap(attr => attr.id).includes(item.id))
        .union(attributeList)
        .orderBy(['order', 'id'])
        .uniqBy('id')
        .value();

      this.checklistAttributesStore.addAttributeListByStageId(stageId, parsedAttributeList);
    });
  };

  setChecklistAttributeListWithoutStage = (
    collection: typeof this.checklistAttributeListWithoutStage
  ) => {
    this.checklistAttributesStore.setChecklistAttributeListWithoutStage(collection);
  };

  addChecklistAttributeListWithoutStage = (
    attributeList: typeof this.checklistAttributeListWithoutStage
  ) => {
    runInAction(() => {
      const existedIdList = attributeList.flatMap(attr => attr.id);

      const parsedAttributeList = _(
        this.checklistAttributesStore.checklistAttributeListWithoutStage || []
      )
        .filter(item => !existedIdList.includes(item.id))
        .union(attributeList)
        .orderBy(['order', 'id'])
        .value();

      this.checklistAttributesStore.setChecklistAttributeListWithoutStage(parsedAttributeList);
    });
  };

  parseNestedFileLinkToClientFormat = (item: IChecklistAttribute): IFileLinkInstanceClientOnly => {
    return {
      imageAttributeId: item.attribute.id,
      imageAttributeName: item.attribute.name,
      attribute: item,
      clientOnlyId: item.id,
      isActive: item.isActive,
      isRequired: item.isRequired,
    };
  };

  setCurrentChecklistAttributeEdit = (attribute: IChecklistAttribute, isNested?: boolean) => {
    this.checklistAttributesStore.setCurrentChecklistAttributeEdit(attribute);

    this.checklistAttributesStore.setCurrentChecklistAttributeEditFileList(
      this.getChildFileLinksList(attribute.id, isNested).map(this.parseNestedFileLinkToClientFormat)
    );
  };

  getChildFileLinksList = (id: string, isNested?: boolean) => {
    if (isNested) {
      return this.checklistAttributeListWithoutStage.filter(item => {
        return (
          item?.attribute?.type === EChecklistAttributeType.File_link &&
          item?.position?.parentId === id
        );
      });
    }

    return this.checklistAttributeListByAllStages.filter(item => {
      return (
        item?.attribute?.type === EChecklistAttributeType.File_link &&
        item?.position?.parentId === id
      );
    });
  };

  changeAttributeInChecklistActivity = (id: string, state: boolean) => {
    return this.checklistAttributeService
      .changeAttributeInChecklist({ id, isActive: state }, true)
      .then(value => {
        if (value?.stageId) {
          this.addAttributeListToStage(value?.stageId ?? 'noStage', [value]);
        } else {
          this.addChecklistAttributeListWithoutStage([value]);
        }

        if (state) {
          this.checklistAttributesStore.addCurrentChecklistBasicAttributeIdListByStage(
            value?.stageId ?? 'noStage',
            [value.attribute.id]
          );
        } else {
          this.checklistAttributesStore.removeCurrentChecklistBasicAttributeIdListByStageAndId(
            value?.stageId ?? 'noStage',
            value.attribute.id
          );
        }

        return value;
      });
  };

  updateNestedFileAttribute = (
    parentAttribute: IChecklistAttribute,
    changedFileLinks: IFileLinkInstanceClientOnly[]
  ) => {
    const updateFileAttribute = (fileInstance: IFileLinkInstanceClientOnly): Promise<any> => {
      if (
        this.currentChecklistAttributeEditFileList?.findIndex(
          (item: IFileLinkInstanceClientOnly) => {
            if (item?.attribute) {
              return (
                fileInstance?.imageAttributeId === item?.attribute?.id ||
                fileInstance?.attribute?.id === item?.attribute?.id
              );
            } else {
              return false;
            }
          }
        ) >= 0
      ) {
        return Promise.reject(Error('Same FILE_LINK'));
      }

      const attributeId = fileInstance?.imageAttributeId || fileInstance?.attribute?.id;

      const payload = {
        order: parentAttribute.order + 1,
        isRequired: fileInstance?.isRequired || false,
        isActive: this.currentChecklistAttributeActiveFile === attributeId,
        parentId: parentAttribute.id,
        stageId: parentAttribute.stageId,
        checkListId: parentAttribute.checkListId,
        attributeId,
      };

      return this.checklistAttributeService.createAttributeInChecklist(payload).then(resp => {
        this.checklistAttributesStore.clearCurrentChecklistAttributeEditFileList();
        this.addAttributeListToStage(resp?.stageId, [resp]);
        return resp;
      });
    };

    return changedFileLinks.map(instance => updateFileAttribute(instance));
  };

  setCurrentChecklistAttributeActiveFileId = (id: string | string[]) => {
    this.checklistAttributesStore.setCurrentChecklistAttributeActiveFileId(id);
  };

  addFileListToCurrentFileList = (fileList: IFileLinkInstanceClientOnly[]) => {
    const existIdList = fileList.flatMap(item => item?.clientOnlyId || item?.attribute?.id);

    this.checklistAttributesStore.setCurrentChecklistAttributeEditFileList(
      _(toJS(this.currentChecklistAttributeEditFileList))
        .filter(value => {
          return !existIdList.includes(value?.clientOnlyId || value?.attribute?.id);
        })
        .union(fileList)
        .value()
    );
  };

  fetchCurrentChecklistAttributeUserDictionaryLinkValueList = async (
    payload: TypeApiRequest<'getChecklistAttributeUserDictionaryList'>
  ) => {
    const result = await this.checklistAttributeService.getChecklistAttributeUserDictionaryList(
      payload
    );

    this.checklistAttributesStore.setCurrentChecklistAttributeUserDictionaryLinkValueList(
      result.content
    );

    return result.content;
  };

  addItemToCurrentChecklistAttributeUserDictionaryLinkValueList = item => {
    this.checklistAttributesStore.setCurrentChecklistAttributeUserDictionaryLinkValueList([
      ...this.checklistAttributesStore.currentChecklistAttributeUserDictionaryLinkValueList.filter(
        valueItem => valueItem.id !== item.id
      ),
      ...item,
    ]);
  };

  activateNestedFileLink = (newActiveItemId?: string, oldActiveItemId?: string | string[]) => {
    const _activateNestedFileLink = (newItemId: string) => {
      if (newItemId) {
        this.changeAttributeInChecklistActivity(newItemId, true).then(resp => {
          this.setCurrentChecklistAttributeActiveFileId(resp.id);
        });
      } else {
        this.setCurrentChecklistAttributeActiveFileId(null);
      }
    };

    if (oldActiveItemId) {
      if (Array.isArray(oldActiveItemId)) {
        this.sanitizeActiveNestedFileLink()?.then(() => _activateNestedFileLink(newActiveItemId));
      } else {
        this.changeAttributeInChecklistActivity(oldActiveItemId, false)?.then(() =>
          _activateNestedFileLink(newActiveItemId)
        );
      }
    }
  };

  sanitizeActiveNestedFileLink = () => {
    const promisedDisabledFileLinkList = this.currentChecklistAttributeEditFileList
      .filter(fileLink => fileLink?.attribute.isActive)
      .map(fileLink => this.changeAttributeInChecklistActivity(fileLink.attribute.id, false));

    return Promise.all(promisedDisabledFileLinkList).then(valueList =>
      this.checklistAttributesStore.setCurrentChecklistAttributeEditFileList(
        this.currentChecklistAttributeEditFileList.map(value => {
          const newAttr: IChecklistAttribute = { ...value?.attribute, isActive: false };

          return { ...value, attribute: newAttr, isActive: false };
        })
      )
    );
  };

  setItemToCurrentChecklistAttributeUserDictionaryLinkValueList = item => {
    this.checklistAttributesStore.setCurrentChecklistAttributeUserDictionaryLinkValueList(item);
  };

  getAttributeInChecklist = (payload: TypeApiRequest<'getAttributeInChecklist'>) => {
    return this.checklistAttributeService.getAttributeInChecklist(payload);
  };

  changeChecklistAttributeUserDictionaryList = (
    payload: TypeApiRequest<'changeChecklistAttributeUserDictionaryList'>
  ) => {
    return this.checklistAttributeService.changeChecklistAttributeUserDictionaryList(payload);
  };

  clearCurrentChecklistAttributeEditFileList = () => {
    this.checklistAttributesStore.clearCurrentChecklistAttributeEditFileList();
  };

  clearCurrentChecklistAttributeEdit = () => {
    this.checklistAttributesStore.clearCurrentChecklistAttributeEdit();
  };
}
