import * as yup from 'yup';

import {
  EFormItemType,
  IAttributeFormItem,
  TAttributeFormItemUnion,
} from '../../../atttibutes/AttributeContainer/components/AttributeBuilderFrom/AttributeBuilderForm.types';

export const attributeInChecklistFormItemsScheme: Record<string, TAttributeFormItemUnion> = {
  name: {
    type: EFormItemType.Autocomplete,
    label: 'Базовый атрибут',
    required: true,
    fetchHandlerApiName: 'getActiveChecklistAttributeList',
  },
  type: {
    type: EFormItemType.String,
    label: 'Тип атрибута',
    required: true,
    disabled: true,
    readonly: true,
    value: '',
  },
  attributeId: {
    type: EFormItemType.String,
    label: 'ID исходного атрибута',
    readonly: true,
    disabled: true,
    value: '',
  },
  calculationType: {
    type: EFormItemType.Select,
    label: 'Тип агрегации',
    value: null,
    disabled: true,
    optionList: [
      { value: 'AVERAGE', label: 'AVERAGE' },
      { value: null, label: 'Не выбрано' },
    ],
  },
  nestedChecklistName: {
    type: EFormItemType.String,
    label: 'Наименование вложенного чек-листа',
    disabled: true,
  },
  checklistLink: {
    type: EFormItemType.String,
    label: 'ID вложенного чек-листа',
    readonly: true,
    disabled: true,
    value: '',
    required: true,
  },
  dictionaryLink: {
    type: EFormItemType.String,
    label: 'Используемый справочник',
    disabled: true,
    required: true,
  },
  dictionaryLinkAlias: {
    type: EFormItemType.String,
    label: 'Алиас справочника',
    readonly: true,
    disabled: true,

    value: '',
  },
  displayParent: {
    type: EFormItemType.Switch,
    label: 'В значении выводить родителя',
    value: false,
  },
  stage: {
    type: EFormItemType.Autocomplete,
    label: 'Группировка',
    required: true,
    fetchHandlerApiName: 'getChecklistStageList',
  },
  stageId: {
    type: EFormItemType.String,
    label: 'ID группировки',
    readonly: true,
    disabled: true,
    value: '',
    required: true,
  },
  order: {
    type: EFormItemType.Number,
    label: 'Номер по порядку',
    value: '',
    required: true,
  },
  id: {
    type: EFormItemType.String,
    label: 'ID атрибута в рамках чек-листа',
    value: '',
    // required: true,
    disabled: true,
  },
  isTitle: {
    type: EFormItemType.Switch,
    label: 'Использовать наименование в заголовке',
    value: false,
  },
  isRequired: {
    type: EFormItemType.Switch,
    label: 'Обязательное заполнение атрибута',
    value: false,
  },
  isMultiselect: {
    type: EFormItemType.Switch,
    label: 'Мультивыбор значений для атрибута',
    value: false,
  },
  'position.parentId': {
    type: EFormItemType.Autocomplete,
    label: 'Родительский атрибут в чек-листе',
    required: true,
    fetchHandlerApiName: 'getChecklistAttributeListByParams',
  },
  position: {
    type: EFormItemType.Custom,
    required: true,
  },
  min: {
    type: EFormItemType.Number,
    label: 'Минимальное значение',
    value: null,
  },
  normal: {
    type: EFormItemType.Number,
    label: 'Нормальное значение',
    value: null,
  },
  max: {
    type: EFormItemType.Number,
    label: 'Максимальное значение',
    value: '',
  },
  precision: {
    type: EFormItemType.Number,
    label: 'Точность округления',
    value: '2',
    required: true,
  },
  dependencyType: {
    type: EFormItemType.Select,
    label: 'Содержание справочника зависит от',
    optionList: [
      { value: 'CHECKLIST_ATTRIBUTE', label: 'CHECKLIST_ATTRIBUTE' },
      { value: 'CULTURE_TASK', label: 'CULTURE_TASK' },
      { value: 'PHENOPHASE_TASK', label: 'PHENOPHASE_TASK' },
      { value: 'OPERATION_TYPE_TASK', label: 'OPERATION_TYPE_TASK' },
    ],
  },
  dependencyTypeAttribute: {
    type: EFormItemType.Autocomplete,
    label: 'Атрибут от которого зависит',
  },
  dependencyAttributeAlias: {
    type: EFormItemType.String,
    label: 'Алиас справочника от кого зависит',
    readonly: true,
    disabled: true,
    value: '',
    required: true,
  },
  fileRequired: {
    type: EFormItemType.Switch,
    label: 'Нужна фотография',
    value: false,
  },
  imageAttribute: {
    type: EFormItemType.Autocomplete,
    label: 'Атрибут для фотографии',
    required: true,
    fetchHandlerApiName: 'getActiveChecklistAttributeList',
  },
  imageAttributeId: {
    type: EFormItemType.String,
    label: 'ID атрибута фотографии',
    readonly: true,
    disabled: true,
    value: '',
    required: true,
  },
  imageFileRequired: {
    type: EFormItemType.Switch,
    label: 'Фото обязательно',
    value: false,
  },

  toolTip: {
    type: EFormItemType.Textarea,
    label: 'Подсказка (toolTip)',
  },
  placeholder: {
    type: EFormItemType.String,
    label: 'Подсказка ввода (placeholder)',
  },
  description: {
    type: EFormItemType.String,
    label: 'Описание атрибута (не отображается в интерфейсе)',
  },
};

export const attributeFormItemsSchemeDefaultValues = Object.entries(
  attributeInChecklistFormItemsScheme
).reduce((prevValue, [curKey, curValue]) => {
  return {
    ...prevValue,
    [curKey]: (curValue as IAttributeFormItem)?.value || null,
  };
}, {});

export const getSchemeValidation = (scheme: EAttributeBuilderFormScheme) => {
  const schemeItems: Set<string> = checklistsAttributeBuilderSchemeMap[scheme];

  // TODO: Заменить на поддержку Yup-схем в `attributeFormItemsScheme`
  if (schemeItems && schemeItems.size > 0) {
    return Array.from(schemeItems).reduce((prev, curr) => {
      if (attributeInChecklistFormItemsScheme[curr]?.required) {
        return { ...prev, [curr]: yup.string().required().typeError('Обязательное поле') };
      } else return prev;
    }, {});
  }
};

type TAttributeFormScheme = keyof typeof attributeInChecklistFormItemsScheme;

export enum EAttributeBuilderFormScheme {
  ALL = 'AllChecklistLinkScheme',
  Boolean = 'BOOLEAN',
  Int = 'IntChecklistLinkScheme',
  Double = 'DoubleChecklistLinkScheme',
  Date = 'DATE',
  String = 'STRING',
  Longstring = 'LONGSTRING',
  DictionaryLink = 'DICTIONARY_LINK',
  Enum = 'ENUM',
  UserDictionaryLink = 'USER_DICTIONARY_LINK',
  File_link = 'FILE_LINK',
  ChecklistInstanceLink = 'ChecklistInstanceLinkScheme',
}

const AllAttributeSchemeScheme: Set<TAttributeFormScheme> = new Set(
  Object.keys(attributeInChecklistFormItemsScheme)
);

const booleanAttributeScheme: Set<TAttributeFormScheme> = new Set([
  'type',
  'name',
  'id',
  'stage',
  'stageId',
  'order',
  'orderId',
  'parentAttribute',
  'parentId',
  'position',
  // 'fileRequired',
  // 'imageAttribute',
  // 'imageAttributeId',
  // 'imageFileRequired',
  'description',
]);

const intAttributeScheme: Set<TAttributeFormScheme> = new Set([
  'type',
  'name',
  'id',
  'calculationType',
  'stage',
  'stageId',
  'order',
  'orderId',
  'isRequired',
  'isMultiselect',
  'parentAttribute',
  'parentId',
  'min',
  'normal',
  'max',
  'position',
  // 'fileRequired',
  // 'imageAttribute',
  // 'imageAttributeId',
  // 'imageFileRequired',
  'toolTip',
  'placeholder',
  'description',
]);

const doubleAttributeScheme: Set<TAttributeFormScheme> = new Set([
  'type',
  'name',
  'id',
  'calculationType',
  'stage',
  'stageId',
  'order',
  'orderId',
  'isRequired',
  'isMultiselect',
  'parentAttribute',
  'precision',
  'parentId',
  'min',
  'normal',
  'max',
  'position',
  // 'fileRequired',
  // 'imageAttribute',
  // 'imageAttributeId',
  // 'imageFileRequired',
  'toolTip',
  'placeholder',
  'description',
]);

const dateAttributeScheme: Set<TAttributeFormScheme> = new Set([
  'type',
  'name',
  'id',
  'stage',
  'stageId',
  'order',
  'orderId',
  'isRequired',
  'parentAttribute',
  'parentId',
  'position',
  // 'fileRequired',
  // 'imageAttribute',
  // 'imageAttributeId',
  // 'imageFileRequired',
  'toolTip',
  'placeholder',
  'description',
]);
const stringAttributeScheme: Set<TAttributeFormScheme> = new Set([
  'type',
  'name',
  'id',
  'stage',
  'stageId',
  'order',
  'orderId',
  'isTitle',
  'isRequired',
  'parentAttribute',
  'parentId',
  'position',
  // 'fileRequired',
  // 'imageAttribute',
  // 'imageAttributeId',
  // 'imageFileRequired',
  'toolTip',
  'placeholder',
  'description',
]);
const longStringAttributeScheme: Set<TAttributeFormScheme> = new Set([
  'type',
  'name',
  'id',
  'stage',
  'stageId',
  'order',
  'orderId',
  'isTitle',
  'isRequired',
  'parentAttribute',
  'parentId',
  'position',
  // 'fileRequired',
  // 'imageAttribute',
  // 'imageAttributeId',
  // 'imageFileRequired',
  'toolTip',
  'placeholder',
  'description',
]);
const enumAttributeScheme: Set<TAttributeFormScheme> = new Set([
  'type',
  'name',
  'id',
  'stage',
  'stageId',
  'order',
  'orderId',
  'isTitle',
  'isRequired',
  'isMultiselect',
  'parentAttribute',
  'parentId',
  'position',
  // 'fileRequired',
  // 'imageAttribute',
  // 'imageAttributeId',
  // 'imageFileRequired',
  'toolTip',
  'placeholder',
  'description',
]);
const userDictionaryLinkAttributeScheme: Set<TAttributeFormScheme> = new Set([
  'type',
  'name',
  'id',
  'order',
  'orderId',
  'isTitle',
  'isRequired',
  'isMultiselect',
  'parentAttribute',
  'parentId',
  'position',
  // 'fileRequired',
  // 'imageAttribute',
  // 'imageAttributeId',
  // 'imageFileRequired',
  'toolTip',
  'placeholder',
  'description',
]);
const dictionaryLinkAttributeScheme: Set<TAttributeFormScheme> = new Set([
  'type',
  'name',
  'id',
  'dictionaryLink',
  'dictionaryLinkAlias',
  'displayParent',
  'stage',
  'stageId',
  'order',
  'orderId',
  'isTitle',
  'isRequired',
  'isMultiselect',
  'parentAttribute',
  'parentId',
  'position',
  // 'dependencyType',
  // 'dependencyTypeAttribute',
  // 'dependencyAttributeAlias',
  // 'fileRequired',
  // 'imageAttribute',
  // 'imageAttributeId',
  // 'imageFileRequired',
  'toolTip',
  'placeholder',
  'description',
]);
const fileLinkAttributeScheme: Set<TAttributeFormScheme> = new Set([
  'type',
  'name',
  'id',
  'stage',
  'stageId',
  'order',
  'orderId',
  'isRequired',
  'isMultiselect',
  'parentAttribute',
  'parentId',
  'position',
  // 'fileRequired',
  // 'imageAttribute',
  // 'imageAttributeId',
  // 'imageFileRequired',
  'toolTip',
  'placeholder',
  'description',
]);

const checklistInstanceLinkAttributeScheme: Set<TAttributeFormScheme> = new Set([
  'type',
  'name',
  'id',
  'nestedChecklistName',
  'checklistLink',
  'stage',
  'stageId',
  'order',
  'orderId',
  'position',
  'isMultiselect',
  'parentAttribute',
  'parentId',
  'description',
]);

export const checklistsAttributeBuilderSchemeMap = {
  ALL: AllAttributeSchemeScheme,
  BOOLEAN: booleanAttributeScheme,
  INT: intAttributeScheme,
  DOUBLE: doubleAttributeScheme,
  DATE: dateAttributeScheme,
  STRING: stringAttributeScheme,
  DICTIONARY_LINK: dictionaryLinkAttributeScheme,
  LONGSTRING: longStringAttributeScheme,
  ENUM: enumAttributeScheme,
  USER_DICTIONARY_LINK: userDictionaryLinkAttributeScheme,
  FILE_LINK: fileLinkAttributeScheme,
  CHECKLIST_INSTANCE_LINK: checklistInstanceLinkAttributeScheme,
};
