import { ETaskStatus } from './task.model';

/**
 * Модель конфига выгрузки.
 * Используется в выгрузке Agrosignal
 *
 * @DTO ReportConfigDto
 * @interface IReportConfig
 */
export interface IReportConfig {
  id: string;
  name: string;
  host: string;
  /**
   * формат `YYYY-MM-DD`
   */
  fromDate: string;
  /**
   * формат `YYYY-MM-DD`
   */
  toDate?: string;
  reportsLocation: string;
  filesLocation: string;
  clientId: number;
  organizationId: string;
  isAuto: boolean;
}

export enum EReportConfigDelimiter {
  COMMA = 'COMMA',
  TAB = 'TAB',
  SEMICOLON = 'SEMICOLON',
}

export enum EReportConfigDecimalSeparator {
  COMMA = 'COMMA',
  DOT = 'DOT',
}

/**
 * Модель конфига выгрузки.
 * Используется в выгрузке данных задач помощника
 *
 * @DTO TaskReportConfigDto
 * @interface ITaskReportConfig
 */
export interface ITaskReportConfig {
  id: string;
  organizationId: string;
  seasonYear: number;
  /**
   * Date format: YYYY-MM-DD
   */
  dateFrom: string;
  /**
   * Date format: YYYY-MM-DD
   */
  dateTo: string;
  cultureIds: string[];
  assigneeIds: string[];
  fieldIds: string[];
  statuses: ETaskStatus[];
  delimiter: EReportConfigDelimiter;
  decimalSeparator: EReportConfigDecimalSeparator;
  withId: boolean;
  enabled: boolean;
  reportsLocation: string;
  filesLocation: string;
}

/**
 * Модель конфига выгрузки.
 * Используется в выгрузке данных полей помощника
 *
 * @DTO FieldReportConfigDto
 * @interface IFieldReportConfig
 */
export interface IFieldReportConfig {
  id: string;
  organizationId: string;
  seasonYear: number;
  enabled: boolean;
  reportsLocation: string;
}
