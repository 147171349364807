import { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { Box, IconButton, Tab, Tabs, Tooltip } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useFormik } from 'formik';
import { useModal, useNotificator } from '@farmlink/farmik-ui';
import { TabContext, TabPanel } from '@mui/lab';
import moment from 'moment';

import { getOrganizationMenu } from '../../../../../../../shared/menu';
import { Layout } from '../../../../../../components';
import { H1, HeaderWrapper, MainBody, MainContainer, TopSection } from '../../../../../../style';
import { AdminRoutes } from '../../../../../../routes';
import { useStore } from '../../../../../../../shared/utils';
import { getNotificatorProps } from '../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../shared/constanst/notifications';
import {
  REMOVE_AGROSIGNAL_CONFIG_MODAL_ID,
  agrosignalReportModalConfig,
  removeAgrosignalConfig,
} from '../../modals';
import {
  EReportConfigDecimalSeparator,
  EReportConfigDelimiter,
  ITaskReportConfig,
} from '../../../../../../../../api/models/reportConfig.model';
import TASK_REPORT_SCHEME from '../../configs/taskReportValidation.scheme';

import { OrganizationReportsAssistanceForm } from './components';
import { AssistanceTaskReportController } from './mobx';

const DEFAULT_FORM_CONFIG: Partial<ITaskReportConfig> = {
  seasonYear: moment().get('year'),
  delimiter: EReportConfigDelimiter.SEMICOLON,
  decimalSeparator: EReportConfigDecimalSeparator.COMMA,
  withId: true,
  reportsLocation: '',
  filesLocation: '',
  dateFrom: null,
  dateTo: null,
  enabled: false,
};

const OrganizationReportsAssistanceTasks = () => {
  const controller = useStore(AssistanceTaskReportController);

  const [defaultConfig, setDefaultConfig] = useState<Partial<ITaskReportConfig>>(
    DEFAULT_FORM_CONFIG
  );
  const [hasValue, setHasValue] = useState(false);
  const [tab, setTab] = useState<'history' | 'config'>('config');

  const handleChange = (event: React.SyntheticEvent, newValue: 'history' | 'config') => {
    setTab(newValue);
  };

  const { organizationId } = useParams<{ organizationId?: string }>();

  const { setNotification } = useNotificator();

  const history = useHistory();

  const { openModalByModalId, registerModalList } = useModal();

  const fetchConfig = () => {
    controller.getReportsConfig(organizationId).then(data => {
      if (data) {
        setDefaultConfig(data);
        setHasValue(true);
      } else {
        setDefaultConfig(DEFAULT_FORM_CONFIG);
        setHasValue(false);
      }
    });
  };

  const submitConfig = (data: ITaskReportConfig) => {
    if (hasValue || data.id) {
      controller
        .updateReportConfig(data.id, data, defaultConfig)
        .then(() => {
          setNotification(
            getNotificatorProps('Конфигурация успешно обновлена', ENotificationStyles.Success)
          );
          fetchConfig();
        })
        .catch((error: Error) => {
          setNotification(
            getNotificatorProps(
              `Ошибка обновления конфигурации${error.message ? `. ${error.message}` : ''}`,
              ENotificationStyles.Error
            )
          );
        });
    } else {
      controller
        .createReportConfig(organizationId, data)
        .then(() => {
          setNotification(
            getNotificatorProps('Конфигурация успешно создана', ENotificationStyles.Success)
          );
          fetchConfig();
        })
        .catch(() => {
          setNotification(
            getNotificatorProps('Ошибка создания конфигурации', ENotificationStyles.Error)
          );
        });
    }
  };

  const deleteConfig = () => {
    openModalByModalId(REMOVE_AGROSIGNAL_CONFIG_MODAL_ID, null, () =>
      controller
        .deleteReportConfig(defaultConfig?.id)
        .then(() => {
          setNotification(
            getNotificatorProps('Конфигурация успешно удалена', ENotificationStyles.Success)
          );
          fetchConfig();
        })
        .catch(() => {
          setNotification(
            getNotificatorProps('Ошибка удаления конфигурации', ENotificationStyles.Error)
          );
        })
    );
  };

  const toggleAutoUpload = (id: string, state: boolean) => {
    if (!hasValue || !id) {
      return null;
    }

    controller
      .toggleAutoUpload(id, state)
      .then(() => {
        setNotification(
          getNotificatorProps(
            `Автоматическая выгрузка успешно ${state ? 'включена' : 'выключена'}`,
            ENotificationStyles.Success
          )
        );
        fetchConfig();
      })
      .catch(() => {
        setNotification(
          getNotificatorProps(
            `Ошибка ${state ? 'включения' : 'выключения'} автоматической выгрузки`,
            ENotificationStyles.Error
          )
        );
      });
  };

  useEffect(() => {
    fetchConfig();

    registerModalList([removeAgrosignalConfig, agrosignalReportModalConfig], 'agrosignal-reports');
  }, []);

  const formik = useFormik<Partial<ITaskReportConfig>>({
    initialValues: defaultConfig,
    validationSchema: TASK_REPORT_SCHEME,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: submitConfig,
  });

  const menuItems = useMemo(() => getOrganizationMenu(organizationId), [organizationId]);
  const backToList = useCallback(
    () => history.push(generatePath(AdminRoutes.OrganizationReports, { organizationId })),
    [organizationId]
  );

  return (
    <Layout menuItems={menuItems}>
      <MainContainer>
        <HeaderWrapper>
          <TopSection>
            <Box position="relative" display="flex" width={'100%'}>
              <Tooltip title="Назад к списку">
                <IconButton onClick={backToList} sx={{ maxHeight: '28px', maxWidth: '28px' }}>
                  <KeyboardArrowLeftIcon />
                </IconButton>
              </Tooltip>
              <Box flexGrow={1}>
                <H1>Отчеты BI. Помощник.Задачи</H1>
              </Box>
              <Box display={'flex'} gap={2} alignItems={'center'}></Box>
            </Box>
          </TopSection>
        </HeaderWrapper>

        <MainBody sx={{ height: 'calc(100vh - 204px)' }}>
          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tab} onChange={handleChange}>
                <Tab label="Настройки" value={'config'} />
              </Tabs>
            </Box>
            <TabPanel value="config">
              <OrganizationReportsAssistanceForm
                formik={formik}
                handleDelete={deleteConfig}
                toggleAutoUpload={toggleAutoUpload}
              />
            </TabPanel>
          </TabContext>
        </MainBody>
      </MainContainer>
    </Layout>
  );
};

export default OrganizationReportsAssistanceTasks;
