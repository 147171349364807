import moment from 'moment';
import _ from 'lodash';

import { ReportService } from '../../../../../../../../../../services';
import { TypeApiRequest, lazyInject, provide } from '../../../../../../../../../../shared/utils';
import {
  ECropwiseFilterIsAuto,
  ICropwiseConfig,
  ICropwiseCreateConfig,
  ICropwiseHistoryFilter,
} from '../../../models';

const HIDED_API_KEY = '****************';

@provide.transient()
class CropwiseReportController {
  @lazyInject(ReportService)
  reportService: ReportService;

  getReportsConfigByOrgId = async (orgId: string): Promise<ICropwiseConfig | false> => {
    const response = await this.reportService.getCropwiseReportsConfigByOrgId({ id: orgId });

    if (!response) {
      return false;
    }

    return this.parseReportConfigToClientConfig(response);
  };

  createReportConfig = async (organizationId: string, config: ICropwiseCreateConfig) => {
    const response = await this.reportService.createCropwiseReportConfig(
      this.prepareFormToCreateConfig(organizationId, config)
    );

    return response;
  };

  updateReportConfig = async (
    configId: string,
    config: Partial<ICropwiseConfig>,
    defaultConfig: Partial<ICropwiseConfig>
  ) => {
    const changes: Partial<ICropwiseConfig> = _.reduce(
      config,
      (result, value, key) => {
        return _.isEqual(value, defaultConfig[key]) ? result : _.extend(result, { [key]: value });
      },
      {}
    );

    if (_.isEmpty(changes)) {
      return Promise.reject(new Error('Нет изменений'));
    }

    const payload = {
      id: configId,
      ...changes,
      ...(changes?.fromDate && { fromDate: this.parseDateToString(changes.fromDate) }),
      ...(changes?.toDate && { toDate: this.parseDateToString(changes.toDate) }),
    };

    const response = await this.reportService.updateCropwiseReportConfig(payload);

    return response;
  };

  deleteReportConfig = async (configId: string) => {
    const response = await this.reportService.deleteCropwiseReportConfig({ id: configId });

    return response;
  };

  triggerDataReport = async (payload: TypeApiRequest<'triggerDataReport'>) => {
    const response = await this.reportService.triggerDataReport(payload);

    return response;
  };

  triggerFilesReport = async (payload: TypeApiRequest<'triggerFilesReport'>) => {
    const response = await this.reportService.triggerFilesReport(payload);

    return response;
  };

  downloadCropwiseReport = async (payload: TypeApiRequest<'downloadCropwiseReport'>) => {
    const response = await this.reportService.downloadCropwiseReport(payload);

    return response;
  };

  getReportImportOperationList = async (
    filterData: ICropwiseHistoryFilter,
    page: number,
    size: number
  ) => {
    const parsedData = {
      ...filterData,
      ...(filterData?.fromDate && {
        fromDate: this.parseDateToString(moment(filterData.fromDate).toDate()),
      }),
      ...(filterData?.toDate && {
        toDate: this.parseDateToString(moment(filterData.toDate).toDate()),
      }),
      page,
      size,
    };

    // @ts-ignore
    if (filterData?.type && filterData?.type === 'Не выбрано') {
      delete parsedData.type;
    }

    switch (filterData?.isAuto) {
      case ECropwiseFilterIsAuto.Auto:
        parsedData.isAuto = true;

        break;

      case ECropwiseFilterIsAuto.NotAuto:
        parsedData.isAuto = false;
        break;

      default:
        delete parsedData.isAuto;
        break;
    }
    // @ts-ignore
    const payload: TypeApiRequest<'getCropwiseReportImportOperationList'> = {
      ...parsedData,
    };

    const response = await this.reportService.getCropwiseReportImportOperationList(payload);

    return response;
  };

  private parseReportConfigToClientConfig = (config: ICropwiseConfig) => {
    const outputData: ICropwiseConfig & { password: string } = {
      ...config,
      password: config?.passwordFilled && HIDED_API_KEY,
      fromDate: config?.fromDate ?? null,
      toDate: config?.toDate ?? null,
    };

    return outputData;
  };

  private prepareFormToCreateConfig = (
    organizationId: string,
    config: ICropwiseCreateConfig & Partial<ICropwiseConfig>
  ): TypeApiRequest<'createCropwiseReportConfig'> => {
    delete config.organizationId;
    delete config.id;
    delete config.organizationName;
    delete config.passwordFilled;

    const output: TypeApiRequest<'createCropwiseReportConfig'> = {
      ...config,
      organizationId,
      ...(config?.fromDate && { fromDate: this.parseDateToString(config.fromDate) }),
      ...(config?.toDate && { toDate: this.parseDateToString(config.toDate) }),
    };

    return output;
  };

  toggleAutoUpload = async (configId: string, state: boolean) => {
    const response = await this.reportService.toggleCropwiseAutoReportUpload(configId, state);

    return response;
  };

  private parseDateToString = (date: any): string => moment(date).format('YYYY-MM-DD');
}

export default CropwiseReportController;
