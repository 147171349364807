import { Box, Button, Divider, FormControlLabel, Grid, Switch, TextField } from '@mui/material';
import { FormikProps } from 'formik';
import { FC } from 'react';

import 'moment/locale/ru';

import { ITaskReportConfig } from '../../../../../../../../../../api/models/reportConfig.model';

interface IProps {
  formik: FormikProps<Partial<ITaskReportConfig>>;
  handleDelete: (id) => void;
  toggleAutoUpload: (id: string, state: boolean) => void;
}

const OrganizationReportsAssistanceForm: FC<IProps> = ({
  formik,
  handleDelete,
  toggleAutoUpload,
}) => {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            label="Сезон"
            onChange={event => {
              const targetValue = event.currentTarget.value;

              if (
                RegExp(/^-?\d+$/).test(targetValue) ||
                targetValue === '' ||
                targetValue === '-' ||
                targetValue?.length <= 4
              ) {
                formik.setFieldValue('seasonYear', targetValue);
              }
            }}
            value={formik.values.seasonYear}
            fullWidth
            error={formik.touched.reportsLocation && Boolean(formik.errors?.reportsLocation)}
            helperText={formik.errors?.reportsLocation}
          />
        </Grid>
        <Grid item xs={5}>
          <FormControlLabel
            control={
              <Switch
                name="enabled"
                checked={Boolean(formik.values.enabled)}
                onChange={(e, value) => {
                  toggleAutoUpload?.(formik?.values?.id, value);

                  formik.setFieldValue('enabled', value);
                }}
              />
            }
            label="Автоматическая выгрузка"
          />
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Путь до папки автоматической выгрузки клиента"
            onChange={event => formik.setFieldValue('reportsLocation', event.target.value)}
            value={formik.values.reportsLocation}
            fullWidth
            error={formik.touched.reportsLocation && Boolean(formik.errors?.reportsLocation)}
            helperText={formik.errors?.reportsLocation}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={2} gap={2}>
          <Button onClick={formik.submitForm} variant="contained" fullWidth>
            Сохранить
          </Button>
        </Grid>
        <Grid item xs={2} gap={2}>
          <Button
            disabled={!Boolean(formik.values?.id)}
            onClick={() => handleDelete(formik.values?.id)}
            color="error"
            variant="text"
          >
            Удалить
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OrganizationReportsAssistanceForm;
