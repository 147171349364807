import omitDeep from 'omit-deep-lodash';
import { isEmpty, trim, truncate } from 'lodash';

import {
  IAttribute,
  IODZ,
} from '../../../../../../../../../../../../../api/models/checklist.attribute.model';
import { lazyInject, provide } from '../../../../../../../../../../../../shared/utils';
import {
  IAggregationFormulaType,
  TFormulaTypeUnion,
} from '../../../../../../../../../modules/Formulas/models';
import { AttributeODZStore } from '../stores';
import { IChecklistAttribute } from '../../../../../../../../../../../../../api/models/checklist.model';

@provide.singleton()
class AttributeODZController {
  @lazyInject(AttributeODZStore)
  protected store: AttributeODZStore;

  getPreparedODZData = () => {
    const redComment = trim(this.store.redCriteriaComment);
    const yellowComment = trim(this.store.yellowCriteriaComment);
    const greenComment = trim(this.store.greenCriteriaComment);

    const combinedData: IODZ = {
      isCriteriaEnabled: this.store.isODZEnabled,
      validationComment: {
        red: redComment?.length > 0 ? redComment : null,
        yellow: yellowComment?.length > 0 ? yellowComment : null,
        green: greenComment?.length > 0 ? greenComment : null,
      },
      validationGreenCriteria: this.sanitizeData(this.store.greenSerializedCriteria),
      validationYellowCriteria: this.sanitizeData(this.store.yellowSerializedCriteria),
      validationRedCriteria: this.sanitizeData(this.store.redSerializedCriteria),
    };

    return combinedData;
  };

  provideODZData = (data: IAttribute | IChecklistAttribute) => {
    this.store.setODZEnabled(Boolean(data.isCriteriaEnabled));

    this.store.setGreenCriteria(data?.validationGreenCriteria);
    this.store.setYellowCriteria(data?.validationYellowCriteria);
    this.store.setRedCriteria(data?.validationRedCriteria);

    if (data.validationComment) {
      this.store.setGreenCriteriaComment(data.validationComment?.green);
      this.store.setYellowCriteriaComment(data.validationComment?.yellow);
      this.store.setRedCriteriaComment(data.validationComment?.red);
    }
  };

  getSaveConsistency = () => {
    const data = this.getPreparedODZData();

    // Хотя бы одна формула включена или все выключены
    if (data.isCriteriaEnabled) {
      return Boolean(
        data.validationRedCriteria || data.validationYellowCriteria || data.validationGreenCriteria
      );
    } else {
      return (
        data.validationRedCriteria === null &&
        data.validationYellowCriteria === null &&
        data.validationGreenCriteria === null
      );
    }
  };

  private sanitizeData = (data: IAggregationFormulaType): TFormulaTypeUnion => {
    const sanitizedData = this.removeClientId(data);

    if (isEmpty(sanitizedData)) return null;

    return sanitizedData as TFormulaTypeUnion;
  };

  removeClientId = (obj: IAggregationFormulaType): TFormulaTypeUnion | {} => {
    return omitDeep(obj, 'clientId');
  };
}

export default AttributeODZController;
