import _ from 'lodash';

/**
 * Deep diff between two object, using lodash
 * @param  {Object} obj1   Object compared
 * @param  {Object} obj2   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export const deepObjectsDiff = (obj1, obj2): { [key: string]: any; } => {
  const changes = (object, base) => {
    return _.transform(object, (result, value, key) => {
      if (!_.isEqual(value, base[key])) {
        result[key] =
          _.isObject(value) && _.isObject(base[key]) ? changes(value, base[key]) : value;
      }
    });
  };
  return changes(obj1, obj2);
};
