export enum ERegexp {
  USERID = ':userId',
  NEWSID = ':newsId',
  ORGANIZATIONID = ':organizationId',
  ALERTID = ':alertId',
  CHECKLISTID = ':checkListId',
}

export enum AdminRoutes {
  Main = '/',

  News = '/news',
  NewsItem = `/news/:newsId`,
  NewsCreate = '/news/create',
  NewsEdit = `/news/:newsId/edit`,
  NewsDelete = '/news/:newsId/delete',

  Users = '/users',
  UserItem = '/users/:userId',
  UserCreate = '/users/create',
  UserEdit = '/users/:userId/edit',
  UserDelete = '/users/:userId/delete',
  UserOrganizations = '/users/:userId/organizations',
  UserNews = '/users/:userId/news',
  UserJournal = '/users/:userId/journal',
  EditExpertises = '/users/:userId/edit-expertises',
  EditInterests = '/users/:userId/edit-interest',

  Services = '/services',
  ReferencesService = '/services/references',
  ReferencesServiceCreate = '/services/references/create',
  ReferencesServiceEdit = '/services/references/:id',

  Calculator = '/calculator',

  Organizations = '/organizations',
  OrganizationItem = '/organizations/:organizationId',
  OrganizationRequisites = '/organizations/:organizationId/requisites',
  OrganizationEmployees = '/organizations/:organizationId/employees',
  OrganizationInvites = '/organizations/:organizationId/invites',
  OrganizationNews = '/organizations/:organizationId/news',
  OrganizationFields = '/organizations/:organizationId/fields',
  OrganizationReports = '/organizations/:organizationId/reports',
  OrganizationReportsPogodavpole = '/organizations/:organizationId/reports/pogodavpole',
  OrganizationReportsAgrosignal = '/organizations/:organizationId/reports/agrosignal',
  OrganizationReportsAssistanceTasks = '/organizations/:organizationId/reports/assistance-tasks',
  OrganizationReportsAssistanceFields = '/organizations/:organizationId/reports/assistance-fields',
  OrganizationReportsCropwise = '/organizations/:organizationId/reports/cropwise',
  OrganizationReportsAgropogoda = '/organizations/:organizationId/reports/agropogoda',
  OrganizationRoleList = '/organizations/:organizationId/roles',
  OrganizationRoleItem = '/organizations/:organizationId/roles/:roleId',
  OrganizationRoleCreate = '/organizations/:organizationId/roles/create',

  Alerts = '/alerts',
  AlertTask = '/alerts/:alertId/task/information',
  CheckListTask = '/alerts/:checkListId/checkList/information',

  Quotes = '/quotes',
  QuotesAdd = '/quotes/add',
  QuotesAddByImport = '/quotes/add/import',
  EditQuote = '/quotes/edit',

  Dictionary = '/dictionary',
  DictionaryCreate = '/dictionary/:name/create',
  DictionarySearch = '/dictionary/search',
  DictionaryName = '/dictionary/:name',
  DictionarySelectedItem = '/dictionary/:name/:itemId',
  DictionarySelectedItemEdit = '/dictionary/:name/:itemId/edit',
  DictionarySelectedItemCopy = '/dictionary/:name/:itemId/copy',
  DictionarySelectedItemHistory = '/dictionary/:name/:itemId/:version',

  Checklists = '/checklists',
  SelectedChecklist = '/checklists/:checklistId',
  ChecklistAttributes = '/checklists/attributes',
  ChecklistBasicAttributeCreate = '/checklists/attributes/create',
  ChecklistBasicAttribute = '/checklists/attributes/:attributeId',
  ChecklistCreate = '/checklists/create',
  ChecklistAttribute = '/checklists/:checklistAttributeId',
  ChecklistAttributeFormulas = '/checklists/:checklistId/:checklistAttributeId/formulas',
}
