import { Box, Button } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { createPortal } from 'react-dom';

import { FormulaContext } from '../../../../../../../../../modules/Formulas/contexts';
import { IAttribute } from '../../../../../../../../../../../../../api/models/checklist.attribute.model';
import { IChecklistAttribute } from '../../../../../../../../../../../../../api/models/checklist.model';
import { ODZItem, ODZToggler } from '../../components';
import { AttributeODZStore } from '../../mobx';
import { useStore } from '../../../../../../../../../../../../shared/utils';
import { useODZContainer } from '../../hooks';
import { EODZCriteria } from '../../models';
import { FormulaErrorLog } from '../../components/FormulaErrorLog';

interface IProps {
  attribute?: IAttribute;
  checklistAttribute?: IChecklistAttribute;
}

const ODZContainer: FC<IProps> = ({ attribute, checklistAttribute }) => {
  const store = useStore(AttributeODZStore);

  const [sidebarNode, setSidebarNode] = useState<Element>(null);

  const controls = useODZContainer();

  useEffect(() => {
    const sidebarContainer = document.querySelector('#aside-menu-inner');

    sidebarContainer.replaceChildren();

    setSidebarNode(sidebarContainer);
  }, []);

  if (!sidebarNode) {
    return null;
  }

  return (
    <Box>
      {createPortal(<FormulaErrorLog errorCollection={store.errorLog} />, sidebarNode)}

      <ODZToggler isChecked={store.isODZEnabled} setToggle={store.setODZEnabled} />
      <FormulaContext.Provider
        value={{ checklistAttributeList: [], checklistAttribute, attribute }}
      >
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          <ODZItem
            data={store?.redCriteria}
            setData={store?.setRedCriteria}
            setSerializedData={store.setRedSerializedCriteria}
            comment={store?.redCriteriaComment}
            setComment={store?.setRedCriteriaComment}
            removeData={() => controls.removeODZ(EODZCriteria.Red)}
            type={EODZCriteria.Red}
            handleRemoveError={controls.removeError}
            handleSetError={(error, id) => controls.addError(EODZCriteria.Red, error, id)}
          />
          <ODZItem
            data={store?.yellowCriteria}
            setData={store?.setYellowCriteria}
            setSerializedData={store?.setYellowSerializedCriteria}
            comment={store?.yellowCriteriaComment}
            setComment={store?.setYellowCriteriaComment}
            removeData={() => controls.removeODZ(EODZCriteria.Yellow)}
            type={EODZCriteria.Yellow}
            handleRemoveError={controls.removeError}
            handleSetError={(error, id) => controls.addError(EODZCriteria.Yellow, error, id)}
          />
          <ODZItem
            data={store?.greenCriteria}
            setData={store?.setGreenCriteria}
            setSerializedData={store?.setGreenSerializedCriteria}
            comment={store?.greenCriteriaComment}
            setComment={store?.setGreenCriteriaComment}
            removeData={() => controls.removeODZ(EODZCriteria.Green)}
            type={EODZCriteria.Green}
            handleRemoveError={controls.removeError}
            handleSetError={(error, id) => controls.addError(EODZCriteria.Green, error, id)}
          />
        </Box>
      </FormulaContext.Provider>
    </Box>
  );
};

ODZContainer.displayName = 'ODZContainer';

export default observer(ODZContainer);
