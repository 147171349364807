import { useModal, useNotificator } from '@farmlink/farmik-ui';

import { CROPWISE_REPORT_MODAL_ID } from '../../../modals';
import { useStore } from '../../../../../../../../shared/utils';
import { getNotificatorProps } from '../../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../../shared/constanst/notifications';
import { downloadBlobAsFile } from '../../../../../../../../shared/utils/downloadBlobAsFile';
import { CropwiseReportController } from '../mobx';
import { ICropwiseConfig } from '../models';

const useUploadTriggers = ({
  organizationId,
}: {
  config: Partial<ICropwiseConfig>;
  organizationId: string;
}) => {
  const controller = useStore(CropwiseReportController);

  const { openModalByModalId, closeModal } = useModal();
  const { setNotification } = useNotificator();

  const downloadReport = () => {
    openModalByModalId(
      CROPWISE_REPORT_MODAL_ID,
      {
        title: 'Формирование отчёта',
      },
      ({ fromDate, toDate }) => {
        controller
          .downloadCropwiseReport({ organizationId, fromDate, toDate })
          .then(data => {
            downloadBlobAsFile(data, `Отчёт Cropwise ${fromDate} - ${toDate}.zip`);
            closeModal();
          })
          .catch(() => {
            setNotification(
              getNotificatorProps('Ошибка формирования отчёта', ENotificationStyles.Error)
            );
          });
      }
    );
  };

  return { downloadReport };
};

export default useUploadTriggers;
