import { Colors, ModalFooter, useModal, useNotificator } from '@farmlink/farmik-ui';
import { Box, LinearProgress, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import moment, { Moment } from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from '@mui/lab';

import { useStore } from '../../../../../../../../../shared/utils';
import { ReportService } from '../../../../../../../../../services';
import { getNotificatorProps } from '../../../../../../../../../shared/utils/getNotificatorProps';
import { ENotificationStyles } from '../../../../../../../../../shared/constanst/notifications';
import { downloadBlobAsFile } from '../../../../../../../../../shared/utils/downloadBlobAsFile';
import { AddWeatherStationForm } from '../../../../components/OrganizationReportsAgropogoda/components';
import { IZondModel } from '../../../../../../../../../../api/models/zond.model';

const AgropogodaReportForm = () => {
  const reportsService = useStore(ReportService);

  const [selectedDevice, setSelectedDevice] = useState<IZondModel>(null);
  const [dateFrom, setDateFrom] = useState<Date>(null);
  const [dateTo, setDateTo] = useState<Date>(null);
  const [isLoading, setIsLoading] = useState(false);

  const { getModalPayload, closeModal } = useModal();
  const { setNotification } = useNotificator();

  const { organizationId } = getModalPayload() as { organizationId: string };

  const isReportDisabled = !dateFrom || !dateTo || isLoading;

  const generateReport = () => {
    const parsedDateFrom = moment(dateFrom).format('YYYY-MM-DD');
    const parsedDateTo = moment(dateTo).format('YYYY-MM-DD');
    setIsLoading(true);

    reportsService
      .getAgropogodaReportPowerBIWeather({
        dateFrom: parsedDateFrom,
        dateTo: parsedDateTo,
        organizationId,
        ...(selectedDevice && { deviceId: selectedDevice.id }),
      })
      .then(blob => downloadBlobAsFile(blob, `Агропогода ${parsedDateFrom} - ${parsedDateTo}.zip`))
      .catch(() => {
        setNotification(
          getNotificatorProps('Ошибка формирования отчёта', ENotificationStyles.Error)
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Box>
      <AddWeatherStationForm
        organizationId={organizationId}
        onSelectDevice={setSelectedDevice}
        isAllOwners
        isHideAddButton
      />

      <Box
        display={'flex'}
        flexDirection={'row'}
        gap={2}
        width="100%"
        marginBottom={4}
        marginTop={2}
        justifyContent={'space-between'}
      >
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ru">
          <Box width={'100%'}>
            <DatePicker
              value={moment(dateFrom)}
              onChange={(date: Moment) => setDateFrom(date.toDate())}
              maxDate={moment(dateTo)}
              PopperProps={{
                style: { zIndex: 10000 },
              }}
              renderInput={props => (
                <TextField {...props} label="Дата начала" error={false} fullWidth />
              )}
            />
          </Box>
          <Box width={'100%'}>
            <DatePicker
              value={moment(dateTo)}
              onChange={(date: Moment) => setDateTo(date.toDate())}
              minDate={moment(dateFrom)}
              PopperProps={{
                style: { zIndex: 10000 },
              }}
              renderInput={props => (
                <TextField {...props} label="Дата окончания" error={false} fullWidth />
              )}
            />
          </Box>
        </LocalizationProvider>
      </Box>

      {isLoading ? (
        <>
          <Typography sx={{ fontSize: 12, color: Colors.grey }}>
            Иногда выгрузка отчёта может занять время
          </Typography>
          <LinearProgress />
        </>
      ) : (
        <Box height={22} />
      )}
      <br />

      <ModalFooter
        successButton={{
          title: 'Сформировать отчёт',
          disabled: isReportDisabled,
          handler: generateReport,
        }}
        denyButton={{ title: 'Отменить', handler: closeModal }}
      />
    </Box>
  );
};

export default AgropogodaReportForm;
