import { ICropwiseConfig } from '../../../../modules/admin/pages/OrganizationsPage/pages/OrganizationReports/components/OrganizationReportsCropwise/models';
import { TypeApiRoute } from '../../../models/type.api.request';

type TypeRequest = {
  id: string;
};
type TypeResponse = ICropwiseConfig;

export const getCropwiseReportsConfigByOrgId: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (params: TypeRequest) => `/api/as-cropwise/reports/config/byOrganizationId/${params.id}`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
