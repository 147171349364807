import { Box, Button, Divider, FormControlLabel, Grid, Switch, TextField } from '@mui/material';
import { FormikProps } from 'formik';
import { FC } from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import { DatePicker, LocalizationProvider } from '@mui/lab';

import 'moment/locale/ru';

import { ICropwiseConfig, ICropwiseCreateConfig } from '../../models';

interface IProps {
  formik: FormikProps<Partial<ICropwiseConfig & ICropwiseCreateConfig>>;
  handleDelete: (id) => void;
  toggleAutoUpload: (id: string, state: boolean) => void;
}

const ReportCropwiseConfigForm: FC<IProps> = ({ formik, handleDelete, toggleAutoUpload }) => {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField
            label="Логин в системе Cropwise"
            value={formik.values.username || ''}
            onChange={event => formik.setFieldValue('username', event.target.value)}
            fullWidth
            error={formik.touched.username && Boolean(formik.errors?.username)}
            helperText={formik.errors?.username}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Пароль в системе Cropwise"
            onChange={event => {
              formik.setFieldValue('password', event.target.value);
            }}
            value={formik.values?.password ?? ''}
            onFocus={() => {
              if (formik.values?.passwordFilled) {
                formik.setFieldValue('password', formik.values?.password.replaceAll('**', ''));
              }
            }}
            fullWidth
            error={formik.touched.password && Boolean(formik.errors?.password)}
            helperText={formik.errors?.password}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label="Сезон"
            onChange={event => {
              const targetValue = event.currentTarget.value;

              if (
                RegExp(/^-?\d+$/).test(targetValue) ||
                targetValue === '' ||
                targetValue === '-' ||
                targetValue?.length <= 4
              ) {
                formik.setFieldValue('seasonYear', targetValue);
              }
            }}
            value={formik.values.seasonYear}
            fullWidth
            error={formik.touched.reportsLocation && Boolean(formik.errors?.reportsLocation)}
            helperText={formik.errors?.reportsLocation}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
            control={
              <Switch
                name="enabled"
                checked={Boolean(formik.values.enabled)}
                onChange={(e, value) => {
                  toggleAutoUpload?.(formik?.values?.id, value);

                  formik.setFieldValue('enabled', value);
                }}
              />
            }
            label="Автоматическая выгрузка"
          />
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={5}>
          <TextField
            label="Путь до папки автоматической выгрузки клиента"
            onChange={event => formik.setFieldValue('reportsLocation', event.target.value)}
            value={formik.values.reportsLocation}
            fullWidth
            error={formik.touched.reportsLocation && Boolean(formik.errors?.reportsLocation)}
            helperText={formik.errors?.reportsLocation}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            label="Путь до папки выгрузки фотоматериалов"
            onChange={event => formik.setFieldValue('filesLocation', event.target.value)}
            value={formik.values.filesLocation}
            fullWidth
            error={formik.touched.filesLocation && Boolean(formik.errors?.filesLocation)}
            helperText={formik.errors?.filesLocation}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ru">
          <Grid item xs={5}>
            <DatePicker
              value={formik?.values?.fromDate}
              onChange={(date: Moment) => {
                if (date) formik.setFieldValue('fromDate', date?.toDate());
              }}
              maxDate={moment(formik?.values?.toDate)}
              renderInput={props => (
                <TextField
                  {...props}
                  label="Дата начала автоматической выгрузки отчёта"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={5}>
            <DatePicker
              value={formik?.values?.toDate}
              onChange={(date: Moment) => {
                if (date) formik.setFieldValue('toDate', date?.toDate());
              }}
              minDate={moment(formik?.values?.fromDate)}
              renderInput={props => (
                <TextField
                  {...props}
                  label="Дата окончания автоматической выгрузки отчёта"
                  fullWidth
                />
              )}
            />
          </Grid>
        </LocalizationProvider>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={2} gap={2}>
          <Button onClick={formik.submitForm} variant="contained" fullWidth>
            Сохранить
          </Button>
        </Grid>
        <Grid item xs={2} gap={2}>
          <Button
            disabled={!Boolean(formik.values?.id)}
            onClick={() => handleDelete(formik.values?.id)}
            color="error"
            variant="text"
          >
            Удалить
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReportCropwiseConfigForm;
