import * as yup from 'yup';

import { EFormItemType, IAttributeFormItem } from './AttributeBuilderForm.types';

export const attributeFormItemsScheme = {
  type: {
    type: EFormItemType.Select,
    label: 'Тип атрибута',
    optionList: [
      // { value: 'ALL', label: 'ALL' },
      { value: 'BOOLEAN', label: 'BOOLEAN' },
      { value: 'INT', label: 'INT' },
      { value: 'DOUBLE', label: 'DOUBLE' },
      { value: 'DATE', label: 'DATE' },
      { value: 'STRING', label: 'STRING' },
      { value: 'LONGSTRING', label: 'LONGSTRING' },
      { value: 'DICTIONARY_LINK', label: 'DICTIONARY_LINK' },
      { value: 'ENUM', label: 'ENUM' },
      { value: 'USER_DICTIONARY_LINK', label: 'USER_DICTIONARY_LINK' },
      { value: 'FILE_LINK', label: 'FILE_LINK' },
      { value: 'CHECKLIST_INSTANCE_LINK', label: 'CHECKLIST_INSTANCE_LINK' },
    ],
    required: true,
  },
  name: {
    type: EFormItemType.String,
    label: 'Наименование атрибута',
    value: '',
    required: true,
  },
  id: {
    type: EFormItemType.String,
    label: 'ID исходного атрибута',
    readonly: true,
    disabled: true,
    value: '',
  },
  isTitle: {
    type: EFormItemType.Switch,
    label: 'Использовать наименование в заголовке',
    value: false,
  },
  isRequired: {
    type: EFormItemType.Switch,
    label: 'Обязательное заполнение атрибута',
    value: false,
  },
  isMultiselect: {
    type: EFormItemType.Switch,
    label: 'Мультивыбор значений для атрибута',
    value: false,
  },
  calculationType: {
    type: EFormItemType.Select,
    label: 'Тип агрегации',
    value: null,
    optionList: [
      { value: 'AVERAGE', label: 'AVERAGE' },
      { value: null, label: 'Не выбрано' },
    ],
  },
  precision: {
    type: EFormItemType.Number,
    label: 'Точность округления',
    value: '2',
    required: true,
  },
  nestedChecklistName: {
    type: EFormItemType.Autocomplete,
    label: 'Наименование вложенного чек-листа',
    fetchHandlerApiName: 'getChecklistListByParams',
    required: true,
  },
  checklistLink: {
    type: EFormItemType.String,
    label: 'ID вложенного чек-листа',
    readonly: true,
    disabled: true,
    value: '',
  },
  usingDictionary: {
    type: EFormItemType.Autocomplete,
    label: 'Используемый справочник',
    fetchHandlerApiName: 'getAllDictionaries',
    required: true,
  },
  dictionaryLink: {
    type: EFormItemType.String,
    label: 'Алиас справочника',
    readonly: true,
    disabled: true,
    required: true,
    value: '',
  },
  displayParent: {
    type: EFormItemType.Switch,
    label: 'В значении выводить родителя',
    value: false,
  },
  toolTip: {
    type: EFormItemType.Textarea,
    label: 'Подсказка (toolTip)',
  },
  placeholder: {
    type: EFormItemType.String,
    label: 'Подсказка ввода (placeholder)',
  },
  description: {
    type: EFormItemType.String,
    label: 'Описание атрибута (не отображается в интерфейсе)',
  },
};

export const attributeFormItemsSchemeDefaultValues = Object.entries(
  attributeFormItemsScheme
).reduce((prevValue, [curKey, curValue]) => {
  return {
    ...prevValue,
    [curKey]: (curValue as IAttributeFormItem)?.value || null,
  };
}, {});

export const getSchemeValidation = (scheme: EAttributeBuilderFormScheme) => {
  const schemeItems: Set<string> = checklistsAttributeBuilderSchemeMap[scheme];

  // TODO: Заменить на поддержку Yup-схем в `attributeFormItemsScheme`
  if (schemeItems && schemeItems.size > 0) {
    return Array.from(schemeItems).reduce((prev, curr) => {
      if (attributeFormItemsScheme[curr]?.required) {
        return { ...prev, [curr]: yup.string().required().typeError('Обязательное поле') };
      } else return prev;
    }, {});
  }
};

type TAttributeFormScheme = keyof typeof attributeFormItemsScheme;

export enum EAttributeBuilderFormScheme {
  ALL = 'AllChecklistLinkScheme',
  Boolean = 'BOOLEAN',
  Int = 'IntChecklistLinkScheme',
  Double = 'DoubleChecklistLinkScheme',
  Date = 'DATE',
  String = 'STRING',
  Longstring = 'LONGSTRING',
  DictionaryLink = 'DICTIONARY_LINK',
  Enum = 'ENUM',
  UserDictionaryLink = 'USER_DICTIONARY_LINK',
  File_link = 'FILE_LINK',
  ChecklistInstanceLink = 'ChecklistInstanceLinkScheme',
}

const AllAttributeSchemeScheme: Set<TAttributeFormScheme> = new Set([
  'type',
  'name',
  'id',
  'isTitle',
  'isRequired',
  'isMultiselect',
  'calculationType',
  'nestedChecklistName',
  'usingDictionary',
  'dictionaryLink',
  'displayParent',
  'toolTip',
  'placeholder',
  'checklistLink',
  'description',
]);

const booleanAttributeScheme: Set<TAttributeFormScheme> = new Set([
  'type',
  'name',
  'id',
  'description',
  'toolTip',
]);

const intAttributeScheme: Set<TAttributeFormScheme> = new Set([
  'type',
  'name',
  'id',
  'isRequired',
  'isMultiselect',
  'calculationType',
  'toolTip',
  'placeholder',
  'description',
]);

const doubleAttributeScheme: Set<TAttributeFormScheme> = new Set([
  'type',
  'name',
  'id',
  'isRequired',
  'isMultiselect',
  'calculationType',
  'precision',
  'toolTip',
  'placeholder',
  'description',
]);

const dateAttributeScheme: Set<TAttributeFormScheme> = new Set([
  'type',
  'name',
  'id',
  'isRequired',
  'toolTip',
  'placeholder',
  'description',
]);
const stringAttributeScheme: Set<TAttributeFormScheme> = new Set([
  'type',
  'name',
  'id',
  'isTitle',
  'isRequired',
  'toolTip',
  'placeholder',
  'description',
]);
const longStringAttributeScheme: Set<TAttributeFormScheme> = new Set([
  'type',
  'name',
  'id',
  'isTitle',
  'isRequired',
  'toolTip',
  'placeholder',
  'description',
]);
const enumAttributeScheme: Set<TAttributeFormScheme> = new Set([
  'type',
  'name',
  'id',
  'isTitle',
  'isRequired',
  'isMultiselect',
  'toolTip',
  'placeholder',
  'description',
]);
const userDictionaryLinkAttributeScheme: Set<TAttributeFormScheme> = new Set([
  'type',
  'name',
  'id',
  'isTitle',
  'isRequired',
  'isMultiselect',
  'toolTip',
  'placeholder',
  'description',
]);
const dictionaryLinkAttributeScheme: Set<TAttributeFormScheme> = new Set([
  'type',
  'name',
  'id',
  'isTitle',
  'isRequired',
  'isMultiselect',
  'usingDictionary',
  'dictionaryLink',
  'displayParent',
  'toolTip',
  'placeholder',
  'description',
]);
const fileLinkAttributeScheme: Set<TAttributeFormScheme> = new Set([
  'type',
  'name',
  'id',
  'isRequired',
  'toolTip',
  'placeholder',
  'description',
]);

const checklistInstanceLinkAttributeScheme: Set<TAttributeFormScheme> = new Set([
  'type',
  'name',
  'id',
  'isMultiselect',
  'nestedChecklistName',
  'checklistLink',
  'description',
]);

export const checklistsAttributeBuilderSchemeMap = {
  // ALL: AllAttributeSchemeScheme,
  BOOLEAN: booleanAttributeScheme,
  INT: intAttributeScheme,
  DOUBLE: doubleAttributeScheme,
  DATE: dateAttributeScheme,
  STRING: stringAttributeScheme,
  DICTIONARY_LINK: dictionaryLinkAttributeScheme,
  LONGSTRING: longStringAttributeScheme,
  ENUM: enumAttributeScheme,
  USER_DICTIONARY_LINK: userDictionaryLinkAttributeScheme,
  FILE_LINK: fileLinkAttributeScheme,
  CHECKLIST_INSTANCE_LINK: checklistInstanceLinkAttributeScheme,
};
