import { Autocomplete, Button, FormControlLabel, Switch, TextField } from '@mui/material';
import { Field, Form, Formik, FormikProps } from 'formik';

import { IAdvancedFilter } from '../../../../../../../../../types/advancedFilter.types';
import { ISelectOptionExtended } from '../../../../../../../../../types/selectOption';
import { EInputType } from '../AdvancedFilter';
import { Styled } from '../styled';

import { IFormConfigItem } from './advancedFilterForm.config';

export const generateFilterForm = (
  formConfig: (IFormConfigItem | IFormConfigItem[])[],
  initialValues,
  onSubmit,
  formik: FormikProps<IAdvancedFilter>,
  onReset: () => void
) => {
  const createFormField = (formConfigItem: IFormConfigItem) => {
    switch (formConfigItem.type) {
      case EInputType.String:
        return (
          <TextField
            name={formConfigItem.id}
            key={formConfigItem.id}
            label={formConfigItem.label}
            id={formConfigItem.id}
            disabled={false}
            onChange={formik.handleChange}
            value={formik.values[formConfigItem.id]}
            fullWidth
          />
        );

      case EInputType.Switch:
        return (
          <Field
            component={() => (
              <FormControlLabel
                control={
                  <Switch
                    id={formConfigItem.id}
                    checked={Boolean(formik.values[formConfigItem.id])}
                    onChange={(event, checked) => formik.setFieldValue(formConfigItem.id, checked)}
                  />
                }
                label={formConfigItem.label}
                name={formConfigItem.id}
              />
            )}
            name={formConfigItem.id}
            type="checkbox"
            fullWidth
          />
        );

      case EInputType.Select:
        return (
          <Autocomplete
            id="select-dictionary"
            options={formConfigItem.selectValues}
            renderInput={params => <TextField {...params} label={formConfigItem.label} />}
            onChange={(event, value: ISelectOptionExtended) => {
              formik.setFieldValue(formConfigItem.id, value);
              formConfigItem?.onSelectValue(value);
            }}
            key={formConfigItem.id}
            value={
              typeof formik.values[formConfigItem.id] === 'number'
                ? String(formik.values[formConfigItem.id])
                : (formik.values[formConfigItem.id] as NonNullable<
                    string | ISelectOptionExtended<any>
                  >)
            }
            noOptionsText="Подходящих элементов не найдено"
            autoComplete
            autoHighlight
            fullWidth
            disableClearable={formConfigItem?.disableClear}
            componentsProps={{
              paper: {
                sx: {
                  width: 300,
                },
              },
            }}
          />
        );

      default:
        return (
          <TextField
            name={formConfigItem.id}
            key={formConfigItem.id}
            label={formConfigItem.label}
            id={formConfigItem.id}
            disabled={false}
            onChange={formik.handleChange}
            value={formik.values[formConfigItem.id]}
            fullWidth
          />
        );
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      <Form onSubmit={formik.handleSubmit}>
        {formConfig.map(formConfigItem =>
          Array.isArray(formConfigItem) ? (
            <Styled.FormRow $isGroup>
              {formConfigItem.map(item => createFormField(item))}
            </Styled.FormRow>
          ) : (
            <Styled.FormRow>{createFormField(formConfigItem)}</Styled.FormRow>
          )
        )}
        <Button onClick={onReset} type="reset">
          Сбросить фильтры
        </Button>
        <Button type="submit" variant="contained" fullWidth>
          Поиск
        </Button>
      </Form>
    </Formik>
  );
};
