import { TFormulaTypeUnion } from '../../modules/admin/modules/checklists/modules/Formulas/models';

import { IChecklistAttribute } from './checklist.model';

export enum EChecklistAttributeType {
  Boolean = 'BOOLEAN',
  Int = 'INT',
  Double = 'DOUBLE',
  Date = 'DATE',
  String = 'STRING',
  Longstring = 'LONGSTRING',
  DictionaryLink = 'DICTIONARY_LINK',
  Enum = 'ENUM',
  UserDictionaryLink = 'USER_DICTIONARY_LINK',
  File_link = 'FILE_LINK',
  ChecklistInstanceLink = 'CHECKLIST_INSTANCE_LINK',
}

export enum EChecklistAttributeCalculationType {
  AVERAGE = 'AVERAGE',
}

export interface IChecklistAttributeEnumItem {
  id?: string;
  value: string;
  isActive: boolean;
  canDelete?: boolean;
}

export interface IChecklistAttributeEnumItemExtended extends IChecklistAttributeEnumItem {
  isHide?: boolean;
  isNew?: boolean;
  organizationId?: string;
}

export interface IAttribute extends IODZ {
  name: string;
  isRequired: boolean;
  isActive: boolean;
  checklistLink: string;
  checklistLinkPublicId: string;
  dictionaryLink: string;
  isMultiselect: boolean;
  displayParent: boolean;
  toolTip: string;
  placeholder: string;
  description: string;
  calculationType: 'AVERAGE';
  id: string;
  type: EChecklistAttributeType;
  isTitle: boolean;
  precision?: number;
}

export interface IAttributeInChecklist extends Partial<IODZ> {
  order: number;
  isRequired?: boolean;
  isActive?: boolean;
  isTitle?: boolean;
  displayParent?: boolean;
  fileRequired?: boolean;
  toolTip?: string;
  parentId?: string;
  dependencyType?: EAttributeInChecklistDependencyType;
  dependencyAttributeId?: string;
  min?: number;
  max?: number;
  normal?: number;
  stageId?: string;
  checkListId?: string;
  attributeId?: string;
  position?: IChecklistAttributePosition;
  calculationType?: 'AVERAGE';
}

export enum EAttributeInChecklistDependencyType {
  CHECKLIST_ATTRIBUTE = 'CHECKLIST_ATTRIBUTE',
  CULTURE_TASK = 'CULTURE_TASK',
  PHENOPHASE_TASK = 'PHENOPHASE_TASK',
  OPERATION_TYPE_TASK = 'OPERATION_TYPE_TASK',
}

export interface IFileLinkInstance {
  isActive?: boolean;
  attribute?: IChecklistAttribute;
}

export interface IFileLinkInstanceClientOnly extends IFileLinkInstance {
  imageAttributeName: string;
  imageAttributeId: string;
  clientOnlyId?: string;
  isRequired?: boolean;
  isDirty?: boolean;
}

export interface IUserDictionaryItemValue {
  id: string;
  value: string;
  organizationId?: string;
  isActive: boolean;
}

export enum EChecklistAttributePositionWidth {
  Full = 'FULL',
  Half = 'HALF',
}

export interface IChecklistAttributePosition {
  parentId: string;
  separate: boolean;
  newLine: boolean;
  width: EChecklistAttributePositionWidth;
}

export interface IODZ {
  isCriteriaEnabled: boolean;
  validationRedCriteria: TFormulaTypeUnion;
  validationYellowCriteria: TFormulaTypeUnion;
  validationGreenCriteria: TFormulaTypeUnion;
  validationComment: {
    red: string;
    yellow: string;
    green: string;
  };
}
