import { useCallback, useContext, useMemo, useState } from 'react';
import { v4 } from 'uuid';
import { useModal } from '@farmlink/farmik-ui';

import {
  EFormulaDependencyType,
  EFormulaFunctionAggregation,
  EFormulaReturnType,
  EFormulaType,
  EFormulaTypes,
  IAggregationFormulaType,
  IDependencyFormulaType,
  IFormulaFunction,
  TFormulaTypeUnion,
} from '../../../../../../models';
import {
  BEFORE_CHANGE_FORMULA_MODAL_ID,
  BEFORE_DELETE_ARGUMENT_FORMULA_MODAL_ID,
} from '../../../../../../modals';
import { useStore } from '../../../../../../../../../../../shared/utils';
import { FormulasStore } from '../../../../../../mobx';
import {
  DEPENDENCY_TYPE_OPTION_LIST,
  FORMULA_DEP_TYPE_LIST,
  FORMULA_ODZ_FORMULA_TYPE_LIST,
  FORMULA_ONLY_FUNCTION_LIST,
  FORMULA_ROOT_TYPE_LIST,
  FORMULA_TYPE_LIST,
  FUNCTION_OPTION_LIST_LOGIC,
  FUNCTION_OPTION_LIST_MATH,
  MATH_LOGIC_FUNCTION_OPTION_LIST_LOGIC,
  ODZ_CHECKLIST_ATTRIBUTE_FORMULA_TYPE_LIST,
  ODZ_FORMULA_TYPE_LIST,
} from '../../../../../../constants';
import { formulaFunctionScheme } from '../../../../../../helpers';
import { FormulaContext } from '../../../../../../contexts';
import { EChecklistAttributeType } from '../../../../../../../../../../../../api/models/checklist.attribute.model';
import { ISelectOptionExtended } from '../../../../../../../../../../../../types/selectOption';
import { IChecklistAttribute } from '../../../../../../../../../../../../api/models/checklist.model';

const useUpdateFormulaData = (
  blockConfig: IAggregationFormulaType,
  setBlockConfig: (config: IAggregationFormulaType) => void,
  formulaFunctionConfig: IFormulaFunction,
  parentBlockConfig: IAggregationFormulaType,
  implementationType: EFormulaTypes,
  isRoot: boolean
) => {
  const { activeEditor, serializedDepFormulaCollection } = useStore(FormulasStore);
  const { openModalByModalId } = useModal();

  const [selectedBlockAttribute, setSelectedBlockAttribute] = useState<
    ISelectOptionExtended<IChecklistAttribute>
  >(null);

  const { checklistAttribute } = useContext(FormulaContext);

  const isChecklistAttribute = Boolean(checklistAttribute);

  const onSelectType = useCallback(
    (value: EFormulaType) => {
      const newData: IAggregationFormulaType = { ...blockConfig, type: value };

      cleanOnChange(newData);

      if (newData?.type === EFormulaType.Function) {
        // @ts-ignore
        newData.args = [];
      }

      setBlockConfig(newData);
    },
    [blockConfig]
  );

  const onAttributeSelect = useCallback(
    (value: string, _attribute: ISelectOptionExtended<IChecklistAttribute>) => {
      const newData = { ...blockConfig, attrId: value };

      delete newData?.propertyName;

      // Заменяем у рядомстоящего аргумента значение для сброса связи UUID H15-4177
      if (parentBlockConfig?.args?.length > 1) {
        const anotherArgument = parentBlockConfig?.args?.find(
          item =>
            item.clientId !== blockConfig?.clientId &&
            item?.type === EFormulaType.Const &&
            item?.returnType === EFormulaReturnType?.Uuid &&
            item?.constantValue?.length > 0
        );

        if (anotherArgument) {
          setSelectedBlockAttribute(null);
          setBlockConfig(newData);
          return;
        }
      }

      setSelectedBlockAttribute(_attribute);
      setBlockConfig(newData);
    },
    [blockConfig, parentBlockConfig]
  );

  const onSelectReturnType = useCallback(
    (value: EFormulaReturnType) => {
      const newData = { ...blockConfig, returnType: value };

      cleanOnChange(newData, { ignoreField: ['functionType'] });

      setBlockConfig(newData);
    },
    [blockConfig]
  );

  const onSelectFunctionType = useCallback(
    (value: EFormulaFunctionAggregation) => {
      if (
        blockConfig?.args?.length &&
        !(blockConfig?.args?.length === 1 && Object.keys(blockConfig?.args[0])?.length <= 1) // аргумент имеет не только clientId
      ) {
        return openModalByModalId(BEFORE_CHANGE_FORMULA_MODAL_ID, null, () => {
          const newData: IAggregationFormulaType = {
            ...blockConfig,
            functionType: value,
            args: [],
          };

          setBlockConfig(newData);
        });
      }

      setBlockConfig({ ...blockConfig, functionType: value });
    },
    [blockConfig, formulaFunctionConfig]
  );

  const onChangeValueType = useCallback(
    (value: string) => setBlockConfig({ ...blockConfig, constantValue: value }),
    [blockConfig]
  );

  const onChangePropertyName = useCallback(
    (value: string) => setBlockConfig({ ...blockConfig, propertyName: value }),
    [blockConfig]
  );

  const onChangeProperty = useCallback(
    (value: string) =>
      setBlockConfig({
        ...blockConfig,
        propertyName: value === null || value === 'null' ? null : value,
      }),
    [blockConfig]
  );

  const onChangeDependencyType = useCallback(
    (value: EFormulaDependencyType) => {
      const newData = { ...blockConfig, dependencyType: value };

      if (value !== EFormulaDependencyType.Attribute) {
        delete newData.attrId;
      }

      setBlockConfig(newData);
    },
    [blockConfig]
  );

  const addArgument = useCallback(() => {
    const newData: IAggregationFormulaType = { ...blockConfig };

    const argList = newData.args || [];

    // @ts-ignore
    argList.push({ clientId: v4() });

    setBlockConfig(newData);
  }, [blockConfig]);

  const removeArgument = useCallback(
    (id: string) => {
      const filteredArgList = (blockConfig?.args || ([] as IAggregationFormulaType[])).filter(
        item => item?.clientId !== id
      );

      const onDelete = () => {
        const newData: IAggregationFormulaType = {
          ...blockConfig,
          args: filteredArgList as TFormulaTypeUnion[],
        };

        setBlockConfig(newData);
      };

      return openModalByModalId(BEFORE_DELETE_ARGUMENT_FORMULA_MODAL_ID, null, () => {
        onDelete();
      });
    },
    [blockConfig]
  );

  const allowedFormulaFunctionList = useMemo(() => {
    if (isRoot) {
      if (!activeEditor && implementationType === EFormulaTypes.ODZ) {
        return FUNCTION_OPTION_LIST_LOGIC;
      }

      switch (activeEditor) {
        case EFormulaTypes.Calculation:
          return FUNCTION_OPTION_LIST_MATH;

        case EFormulaTypes.Visibility:
          return FUNCTION_OPTION_LIST_LOGIC;

        default:
          return null;
      }
    } else {
      switch (activeEditor) {
        case EFormulaTypes.Calculation:
          return FUNCTION_OPTION_LIST_MATH;

        case EFormulaTypes.Visibility:
          if (
            [
              EFormulaFunctionAggregation.AND,
              EFormulaFunctionAggregation.OR,
              EFormulaFunctionAggregation.XOR,
              EFormulaFunctionAggregation.NOT,
            ].includes(parentBlockConfig?.functionType)
          ) {
            return MATH_LOGIC_FUNCTION_OPTION_LIST_LOGIC;
          }

          if (
            [
              EFormulaFunctionAggregation.GT,
              EFormulaFunctionAggregation.GOE,
              EFormulaFunctionAggregation.LT,
              EFormulaFunctionAggregation.LOE,
            ].includes(parentBlockConfig?.functionType)
          ) {
            return FUNCTION_OPTION_LIST_MATH;
          }

          return null;

        default:
          return null;
      }
    }
  }, [blockConfig, isRoot, parentBlockConfig]);

  const allowedFormulaReturnType = useMemo<EFormulaReturnType[]>(() => {
    // TODO: избавиться от такой логики
    if (isRoot) {
      if (implementationType === EFormulaTypes.Calculation) {
        if (checklistAttribute?.attribute?.type === EChecklistAttributeType.Int) {
          return [EFormulaReturnType.Integer];
        }

        if (checklistAttribute?.attribute?.type === EChecklistAttributeType.Double) {
          return [EFormulaReturnType.Double];
        }
      }

      if (implementationType === EFormulaTypes.Visibility) {
        return [EFormulaReturnType.Boolean];
      }

      if (implementationType === EFormulaTypes.Dependency) {
        return [EFormulaReturnType.Void];
      }

      if (implementationType === EFormulaTypes.ODZ) {
        return [EFormulaReturnType.Boolean];
      }
    }

    if (parentBlockConfig?.type === EFormulaType.Function) {
      const parentFunctionConfig = formulaFunctionScheme.get(parentBlockConfig?.functionType);

      if (parentFunctionConfig?.primitiveType?.length) {
        return parentFunctionConfig?.primitiveType;
      }
    }

    return formulaFunctionConfig?.returnType;
  }, [
    blockConfig,
    formulaFunctionConfig,
    parentBlockConfig,
    implementationType,
    checklistAttribute?.attribute?.type,
    isRoot,
  ]);

  const allowedFormulaType = useMemo(() => {
    if (isRoot) {
      if (
        implementationType === EFormulaTypes.Calculation ||
        implementationType === EFormulaTypes.Visibility
      ) {
        return FORMULA_ROOT_TYPE_LIST;
      }

      if (implementationType === EFormulaTypes.ODZ) {
        return FORMULA_ODZ_FORMULA_TYPE_LIST;
      }

      if (implementationType === EFormulaTypes.Dependency) {
        return FORMULA_DEP_TYPE_LIST;
      }
    }

    if (implementationType === EFormulaTypes.ODZ) {
      if (isChecklistAttribute) {
        return ODZ_CHECKLIST_ATTRIBUTE_FORMULA_TYPE_LIST;
      }

      return ODZ_FORMULA_TYPE_LIST;
    }

    if (
      [
        EFormulaFunctionAggregation.AND,
        EFormulaFunctionAggregation.OR,
        EFormulaFunctionAggregation.XOR,
        EFormulaFunctionAggregation.NOT,
      ].includes(parentBlockConfig?.functionType)
    ) {
      return FORMULA_ONLY_FUNCTION_LIST;
    }

    return FORMULA_TYPE_LIST;
  }, [
    blockConfig,
    formulaFunctionConfig,
    parentBlockConfig,
    implementationType,
    checklistAttribute?.attribute?.type,
    isRoot,
  ]);

  const allowedFormulaDependency = useMemo(() => {
    if (isRoot) {
      switch (checklistAttribute?.attribute?.type) {
        case EChecklistAttributeType.Enum:
          return [
            { value: EFormulaDependencyType.Attribute, label: EFormulaDependencyType.Attribute },
          ];

        default:
      }
    }

    return DEPENDENCY_TYPE_OPTION_LIST;
  }, [
    blockConfig,
    parentBlockConfig,
    checklistAttribute?.attribute?.type,
    isRoot,
    serializedDepFormulaCollection,
  ]);

  const attrTypeFilter = useMemo<EChecklistAttributeType[]>(() => {
    if (blockConfig?.type === EFormulaType.Property) {
      return [EChecklistAttributeType.DictionaryLink, EChecklistAttributeType.Enum];
    }

    if (isRoot) {
      if (
        checklistAttribute?.attribute?.type === EChecklistAttributeType.Enum ||
        checklistAttribute?.attribute?.type === EChecklistAttributeType.DictionaryLink
      ) {
        return [checklistAttribute?.attribute?.type];
      }
    }

    if (blockConfig?.returnType) {
      switch (blockConfig?.returnType) {
        case EFormulaReturnType.Boolean:
          return [EChecklistAttributeType.Boolean];

        case EFormulaReturnType.Date:
          return [EChecklistAttributeType.Date];
        case EFormulaReturnType.Integer:
          return [
            EChecklistAttributeType.Int,
            EChecklistAttributeType.Double,
            EChecklistAttributeType.Enum,
            EChecklistAttributeType.DictionaryLink,
          ];
        case EFormulaReturnType.Double:
          return [
            EChecklistAttributeType.Int,
            EChecklistAttributeType.Double,
            EChecklistAttributeType.Enum,
            EChecklistAttributeType.DictionaryLink,
          ];
        case EFormulaReturnType.String:
          return [EChecklistAttributeType.String, EChecklistAttributeType.Enum];

        case EFormulaReturnType.Uuid:
          return [EChecklistAttributeType.Enum, EChecklistAttributeType.DictionaryLink];

        default:
          return null;
      }
    }

    return null;
  }, [
    blockConfig,
    formulaFunctionConfig,
    parentBlockConfig,
    implementationType,
    checklistAttribute?.attribute?.type,
    isRoot,
  ]);

  const attrTypeFilterIdList = useMemo(() => {
    const usedAttrs = new Set<string>();

    Array.from(serializedDepFormulaCollection.values()).forEach((item: IDependencyFormulaType) => {
      if (item.dependencyType === EFormulaDependencyType.Attribute && item.attrId) {
        usedAttrs.add(item.attrId);
      }
    });

    if (usedAttrs.size > 0) {
      return Array.from(usedAttrs.values());
    }

    return null;
  }, [
    blockConfig,
    formulaFunctionConfig,
    parentBlockConfig,
    implementationType,
    checklistAttribute?.attribute?.type,
    serializedDepFormulaCollection,
    isRoot,
  ]);

  const cleanOnChange = (
    data: IAggregationFormulaType,
    options?: { ignoreField: Array<keyof IAggregationFormulaType> }
  ) => {
    delete data.constantValue;
    delete data.attrId;
    delete data.dependencyType;
    delete data.propertyName;

    if (!options?.ignoreField?.includes('functionType')) {
      delete data.functionType;
    }
  };

  const isBasicAttributeODZ = useMemo(
    () => !Boolean(checklistAttribute) && implementationType === EFormulaTypes.ODZ,
    [implementationType, checklistAttribute]
  );

  return {
    onSelectType,
    onAttributeSelect,
    onSelectReturnType,
    onSelectFunctionType,
    onChangeValueType,
    onChangePropertyName,
    onChangeDependencyType,
    onChangeProperty,
    addArgument,
    removeArgument,
    allowedFormulaFunctionList,
    allowedFormulaReturnType,
    allowedFormulaType,
    allowedFormulaDependency,
    attrTypeFilter,
    attrTypeFilterIdList,
    selectedBlockAttribute,
    isBasicAttributeODZ,
  };
};

export default useUpdateFormulaData;
