import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { DataGrid, GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { v4 } from 'uuid';

import {
  EChecklistType,
  IChecklistSettingsEntity,
  IChecklistSettingsEntityShort,
} from '../../../../../../../../api/models/checklist.model';
import {
  EDictionaryItemStatus,
  TDictionaryItem,
} from '../../../../../../../../api/models/dictionaries.model';
import { ISelectOption, ISelectOptionExtended } from '../../../../../../../../types/selectOption';
import { ChecklistCRUDController } from '../../../../../../../controllers/checklist.CRUD.controller';
import { DictionariesController } from '../../../../../../../controllers/dictionaries.controller';
import { AutocompleteWithSearch } from '../../../../../../../shared/components';
import { useStore } from '../../../../../../../shared/utils';
import { getSubstringName } from '../../../../helpers/getSubstringName';

import { TooltipedValue } from './components';

interface IProps {
  hideTable?: boolean;
  onChange?: (value: Partial<IChecklistSettingsEntity>) => void;
}

const Settings = observer(({ hideTable, onChange }: IProps) => {
  const {
    currentChecklist,
    currentChecklistSettingsList,
    currentChecklistPropertySwitches,
    createChecklistSettingsItem,
    currentChecklistId,
    removeChecklistSettingsItem,
  } = useStore(ChecklistCRUDController);
  const { getAllDictionariesByParams } = useStore(DictionariesController);

  const [selectedTechOperation, setSelectedTechOperation] = useState<
    ISelectOptionExtended | string
  >(null);
  const [selectedCulture, setSelectedCulture] = useState<ISelectOption | string>(null);
  const [selectedPhenophase, setSelectedPhenophase] = useState<ISelectOption | string>(null);
  const [selectedSettings, setSelectedSettings] = useState<GridSelectionModel>([]);

  const isIntensityRequired = (selectedTechOperation as ISelectOptionExtended)?.rawData.attrs
    .intensityRequired;
  const isMachinery =
    currentChecklistPropertySwitches?.machinery ||
    currentChecklist?.type === EChecklistType.Machinery;
  const isEnabledAddButton =
    (isMachinery && selectedTechOperation && selectedCulture && selectedPhenophase === null) ||
    (!isMachinery &&
      selectedTechOperation &&
      selectedCulture &&
      selectedPhenophase &&
      isIntensityRequired) ||
    (!isMachinery &&
      selectedTechOperation &&
      selectedCulture &&
      selectedPhenophase === null &&
      !isIntensityRequired);

  useEffect(() => {
    if (isMachinery || !isIntensityRequired) {
      setSelectedPhenophase(null);
    }
  }, [isMachinery, isIntensityRequired]);

  const addChecklistSettingItem = useCallback(() => {
    const settingItem: Partial<IChecklistSettingsEntityShort & IChecklistSettingsEntity> = {
      checkListId: v4(),
    };

    if (typeof selectedTechOperation === 'object') {
      settingItem.operationTypeId = selectedTechOperation.value as string;
      settingItem.operationType = {
        name: getSubstringName(selectedTechOperation.label),
        id: selectedTechOperation.value as string,
      };
    }
    if (typeof selectedCulture === 'object') {
      settingItem.cultureId = selectedCulture.value;
      settingItem.culture = {
        name: getSubstringName(selectedCulture.label),
        id: selectedCulture.value,
      };
    }
    if (typeof selectedPhenophase === 'object') {
      settingItem.phenophaseId = selectedPhenophase?.value || '';
      settingItem.phenophase = {
        name: getSubstringName(selectedPhenophase?.label) || '',
        id: selectedPhenophase?.value || '',
      };
    }

    if (onChange) {
      onChange?.(settingItem);
    } else {
      createChecklistSettingsItem(settingItem);
    }
    cleanAfterAdd();
  }, [selectedCulture, selectedPhenophase, selectedTechOperation, isMachinery, currentChecklistId]);

  const dataGridRows = useMemo(
    () =>
      toJS(currentChecklistSettingsList)?.map(
        (item: IChecklistSettingsEntity & IChecklistSettingsEntityShort) => {
          return {
            ...item,
            id: item?.id || v4(),
            operationTypeId: item?.operationTypeId || item?.operationType?.id || '',
            operationTypeName: item?.operationType?.name || '',
            cultureId: item.cultureId || item?.culture?.id || '',
            cultureName: item?.culture?.name || '',
            phenophaseId: item.phenophaseId || item?.phenophase?.id || '',
            phenophaseName: item?.phenophase?.name || '',
          };
        }
      ),
    [currentChecklistSettingsList]
  );

  const removeSettingsItem = useCallback(
    () => removeChecklistSettingsItem(selectedSettings as string[]),
    [selectedSettings]
  );

  const dataGridColumns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'operationTypeId',
        headerName: 'ID Тех. операции',
        flex: 1,
        renderCell: params => <TooltipedValue value={params.value} dblClickToCopy />,
      },
      {
        field: 'operationTypeName',
        headerName: 'Наименование Тех. операции',
        flex: 1,
        renderCell: params => <TooltipedValue value={params.value} />,
      },
      {
        field: 'cultureId',
        headerName: 'ID Культуры',
        flex: 1,
        renderCell: params => <TooltipedValue value={params.value} dblClickToCopy />,
      },
      {
        field: 'cultureName',
        headerName: 'Наименование Культуры',
        flex: 1,
        renderCell: params => <TooltipedValue value={params.value} />,
      },
      {
        field: 'phenophaseId',
        headerName: 'ID Фенофазы',
        flex: 1,
        renderCell: params => <TooltipedValue value={params.value} dblClickToCopy />,
      },
      {
        field: 'phenophaseName',
        headerName: 'Наименование Фенофазы',
        flex: 1,
        renderCell: params => <TooltipedValue value={params.value} />,
      },
    ],
    [currentChecklistSettingsList]
  );

  const cleanAfterAdd = useCallback(() => {
    setSelectedCulture(null);
    setSelectedPhenophase(null);
    setSelectedTechOperation(null);
  }, []);

  return (
    <div>
      <Box display="flex" gap="8px">
        <AutocompleteWithSearch
          selectedValue={selectedTechOperation}
          setSelectedOption={value => {
            setSelectedTechOperation(value as ISelectOption);
            setSelectedCulture(null);
            setSelectedPhenophase(null);
          }}
          fetchHandler={payload =>
            getAllDictionariesByParams('techOperationType', {
              ...payload,
              remoteName: 'techOperationType',
              latestVersion: true,
              fetchAttributes: true,
              status: EDictionaryItemStatus.Active,
              originalOnly: true,
              size: 20,
              page: 0,
            })
          }
          responseMappingHandler={(data: TDictionaryItem[]) =>
            data.map(item => ({
              label: `${item?.name ? `${item.name} / ` : ''}${item.id}`,
              value: item.id,
              rawData: item,
            }))
          }
          label="Тех. операция"
          fullWidth
          searchPropertyKey="nameFilter"
          isClearable
        />
        <AutocompleteWithSearch
          selectedValue={selectedCulture}
          setSelectedOption={setSelectedCulture}
          fetchHandler={payload => {
            return getAllDictionariesByParams('culture', {
              ...payload,
              remoteName: 'culture',
              latestVersion: true,
              fetchAttributes: true,
              status: EDictionaryItemStatus.Active,
              originalOnly: true,
              attrs: { useInAssistance: true },
              dependencyName: 'techOperationType',
              size: 20,
              page: 0,
            });
          }}
          additionalRequestPayload={
            selectedTechOperation
              ? { dependencyRecordId: (selectedTechOperation as ISelectOption)?.value }
              : null
          }
          responseMappingHandler={(data: TDictionaryItem[]) =>
            data.map(item => ({
              label: `${item?.name ? `${item.name} / ` : ''}${item.id}`,
              value: item.id,
            }))
          }
          label="Культура"
          fullWidth
          searchPropertyKey="nameFilter"
        />
        {isMachinery || !isIntensityRequired ? null : (
          <AutocompleteWithSearch
            selectedValue={selectedPhenophase}
            setSelectedOption={setSelectedPhenophase}
            fetchHandler={payload => {
              return getAllDictionariesByParams('phenophase', {
                ...payload,
                remoteName: 'phenophase',
                latestVersion: true,
                fetchAttributes: true,
                status: EDictionaryItemStatus.Active,
                originalOnly: true,
                dependencyName: 'culture',
                directDependencies: true,
              });
            }}
            additionalRequestPayload={
              selectedCulture
                ? { dependencyRecordId: (selectedCulture as ISelectOption)?.value }
                : null
            }
            responseMappingHandler={(data: TDictionaryItem[]) =>
              data.map(item => ({
                label: `${item?.name ? `${item.name} / ` : ''}${item.id}`,
                value: item.id,
              }))
            }
            label="Фенофаза"
            fullWidth
          />
        )}
        <Button
          variant="contained"
          disabled={!isEnabledAddButton}
          onClick={addChecklistSettingItem}
          fullWidth
        >
          Добавить
        </Button>
      </Box>
      {!hideTable && (
        <>
          <Box display="flex" marginTop={1}>
            <Button variant="text" color="error" onClick={removeSettingsItem}>
              Удалить выбранное
            </Button>
          </Box>
          {
            <div style={{ display: 'flex', height: '95%', minHeight: '500px' }}>
              <div style={{ flexGrow: 1 }}>
                <DataGrid
                  rows={dataGridRows || []}
                  columns={dataGridColumns}
                  hideFooter
                  disableColumnFilter
                  disableColumnMenu
                  disableColumnSelector
                  localeText={{ noRowsLabel: 'Нет результатов' }}
                  checkboxSelection
                  onSelectionModelChange={newSelectionModel => {
                    setSelectedSettings(newSelectionModel);
                  }}
                  selectionModel={selectedSettings}
                />
              </div>
            </div>
          }
        </>
      )}
    </div>
  );
});

export default memo(Settings);
