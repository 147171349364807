import moment from 'moment';
import _ from 'lodash';

import { TypeApiRequest, lazyInject, provide } from '../../../../../../../../../../shared/utils';
import { IFieldReportConfig } from '../../../../../../../../../../../api/models/reportConfig.model';
import { AssistanceReportService } from '../../../../../../../../../../services/reports/assistance/assistanceReports.service';

@provide.transient()
class AssistanceFieldReportController {
  @lazyInject(AssistanceReportService)
  reportService: AssistanceReportService;

  getReportsConfig = async (orgId: string): Promise<IFieldReportConfig | false> => {
    const response = await this.reportService.getAssistanceFieldReportConfig({
      organizationId: orgId,
    });

    if (!response) {
      return false;
    }

    return response.content[0];
  };

  createReportConfig = async (organizationId: string, config: IFieldReportConfig) => {
    const response = await this.reportService.createAssistanceFieldReportConfig(
      this.prepareFormToCreateConfig(organizationId, config)
    );

    return response;
  };

  updateReportConfig = async (
    configId: string,
    config: Partial<IFieldReportConfig>,
    defaultConfig: Partial<IFieldReportConfig>
  ) => {
    const changes: Partial<IFieldReportConfig> = _.reduce(
      config,
      (result, value, key) => {
        return _.isEqual(value, defaultConfig[key]) ? result : _.extend(result, { [key]: value });
      },
      {}
    );

    const payload = {
      id: configId,
      ...changes,
    };

    const response = await this.reportService.updateAssistanceFieldReportConfigBy(payload);

    return response;
  };

  toggleAutoUpload = async (configId: string, state: boolean) => {
    const response = await this.reportService.toggleAssistanceFieldReportAutoUpload(
      configId,
      state
    );

    return response;
  };

  deleteReportConfig = async (configId: string) => {
    const response = await this.reportService.deleteAssistanceFieldReportConfigBy({ id: configId });

    return response;
  };

  private prepareFormToCreateConfig = (
    organizationId: string,
    config: IFieldReportConfig
  ): TypeApiRequest<'createAssistanceFieldReportsConfig'> => {
    const output: TypeApiRequest<'createAssistanceFieldReportsConfig'> = {
      organizationId,
      ...config,
    };

    return output;
  };
}

export default AssistanceFieldReportController;
